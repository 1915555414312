import { CONFIG } from '../../../config';
import { Endpoints } from '../../../utils/endpoints';
import { AuthCookieService } from '../../../services/cookie';
import FroalaEditor, { FroalaOptions } from 'froala-editor';
export type EditorConfig = Partial<FroalaOptions>;

const toolbarButtonsMD = {
  // Key represents the more button from the toolbar.
  moreText: {
    // List of buttons used in the  group.
    buttons: ['paragraphFormat', 'fontFamily'],

    // Alignment of the group in the toolbar.
    align: 'left',

    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
    buttonsVisible: 2,
  },

  moreParagraph: {
    buttons: [
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      'fontSize',
      'alignLeft',
      'alignCenter',
      'alignRight',
      'alignJustify',
    ],
    align: 'left',
    buttonsVisible: 6,
  },

  moreMisc: {
    buttons: ['formatUL', 'formatOL', 'lineHeight', 'insertLink', 'insertImage', 'clearFormatting', 'chatGPT'],
    align: 'right',
    buttonsVisible: 0,
  },
};
const toolbarButtonsSM = {
  // Key represents the more button from the toolbar.
  moreText: {
    // List of buttons used in the  group.
    buttons: ['paragraphFormat', 'fontFamily'],

    // Alignment of the group in the toolbar.
    align: 'left',

    // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
    buttonsVisible: 2,
  },

  moreParagraph: {
    buttons: [
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      'fontSize',
      'alignLeft',
      'alignCenter',
      'alignRight',
      'alignJustify',
    ],
    align: 'left',
    buttonsVisible: 6,
  },

  moreMisc: {
    buttons: ['formatUL', 'formatOL', 'lineHeight', 'insertLink', 'insertImage', 'clearFormatting', 'chatGPT'],
    align: 'right',
    buttonsVisible: 0,
  },
};

export const editorConfig: EditorConfig = {
  height: '100%',
  pasteDeniedTags: ['img'],
  placeholderText: 'Write text here...',
  key: CONFIG.FROALA_LICENSE_KEY,
  toolbarContainer: '#editor-froala-toolbar',
  charCounterCount: false,
  quickInsertEnabled: false,
  imageUpload: true,
  imageMaxSize: 5242880, // 5MB
  acceptedImageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  events: {
    'image.beforeUpload': function (this: FroalaEditor) {
      this.opts.requestHeaders = { Authorization: `Bearer ${AuthCookieService.getAccessToken()}` };
      return true;
    },
    initialized: function (this: FroalaEditor) {
      this.events.on(
        'keydown',
        (e) => {
          if (e.which == FroalaEditor.KEYCODE.ENTER && !!e.currentTarget?.tributeMenu) {
            return false;
          }
        },
        true
      );
    },
  },
  shortcutsEnabled: ['show', 'bold', 'italic', 'underline', 'strikeThrough', 'indent', 'outdent', 'insertImage', 'createLink'],
  imageUploadURL: `${globalThis.env.VITE_API_ENDPOINT}${Endpoints.uploadImage}`,
  toolbarButtons: [
    '|',
    'paragraphFormat',
    'fontFamily',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'fontSize',
    'alignLeft',
    'alignCenter',
    'alignRight',
    'alignJustify',
    'formatUL',
    'formatOL',
    'lineHeight',
    'insertLink',
    // Hiding Image function as the layout is not fully functional
    // 'insertImage',
    'clearFormatting',
    '|',
    'chatGPT',
  ],
  toolbarButtonsMD,
  toolbarButtonsSM,
  paragraphFormatSelection: true,
  fontFamilySelection: true,
  attribution: false,
  fontSizeSelection: true,
  fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '32', '36', '48', '60', '72', '96'],
};

export const defaultFont = [
  { value: 'Georgia,serif', name: 'Georgia' },
  { value: 'Impact,Charcoal,sans-serif', name: 'Impact' },
  { value: 'Tahoma,Geneva,sans-serif', name: 'Tahoma' },
  { value: 'Verdana,Geneva,sans-serif', name: 'Verdana' },
  { value: "'Times New Roman',Times,serif", name: 'Times New Roman' },
];

import { useTranslation } from 'react-i18next';
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../grid/reduxStore/Store';
import { updateSectionTitle } from '../grid/reduxStore/editorSlice';
import { useSocketClient } from '../../../providers/SocketContext';
import { AcknowledgmentResponseStatus, DefaultSocketResponseType, SectionEvents } from '../../../services/socket/SocketEvents';
import { setSaveStatus } from '../grid/reduxStore/saveStatusSlice';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';

const SectionTitleInput = ({ sectionId }: { sectionId: string }) => {
  const sectionTitle = useSelector((state: RootState) => state.gridBlockReducer.sections[sectionId].title);
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const oldTitleRef = useRef(sectionTitle);
  const currentSaveStatus = useSelector((state: RootState) => state.saveStatus.currentSaveStatus);

  const dispatch = useAppDispatch();
  const { socketClient } = useSocketClient();
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const { t } = useTranslation();

  const textFieldStyles = useMemo(
    () => ({
      '& .MuiOutlinedInput-root': {
        padding: '8px 12px',
        fontSize: '20px',
        fontWeight: 600,
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0000003B',
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    }),
    []
  );

  const setSectionTitleState = useCallback(
    (title: string) => {
      dispatch(
        updateSectionTitle({
          sectionId: sectionId,
          sectionTitle: title,
        })
      );
    },
    [dispatch, sectionId, updateSectionTitle]
  );

  const handleTitleChangeSocketCallback = (response: DefaultSocketResponseType) => {
    if (response.status === AcknowledgmentResponseStatus.OK) {
      oldTitleRef.current = sectionTitle;
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVED }));
      return;
    }

    setSectionTitleState(oldTitleRef.current);
    dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED }));
    checkDocumentLockStatus(response.errorCode);
  };

  const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      inputFieldRef.current?.blur();
    }
  };

  const getDefaultTitleIfEmpty = (title: string): string => {
    const trimmedTitle = title.trim();
    return trimmedTitle || t('editor.default_content_section_title');
  };

  const updateSectionTitleWS = useCallback(
    (newTitle: string) => {
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
      newTitle = getDefaultTitleIfEmpty(newTitle);
      setSectionTitleState(newTitle);
      socketClient.publish(SectionEvents.SECTION_CHANGED, { id: sectionId, title: newTitle }, handleTitleChangeSocketCallback);
    },
    [dispatch, setSectionTitleState, socketClient, sectionId, handleTitleChangeSocketCallback]
  );

  // This is to ensure that the title is saved when the component is unmounted to account for LazyLoadComponent. Update the title when the component is unmounted if the title has changed and the save status is not saved, cover the case when document is locked.

  useEffect(() => {
    return () => {
      const shouldUpdateSectionTitle = sectionTitle && oldTitleRef.current !== sectionTitle && currentSaveStatus.status === 1;
      if (shouldUpdateSectionTitle) {
        updateSectionTitleWS(sectionTitle);
      }
    };
  }, [sectionTitle, currentSaveStatus, DocumentSaveStatus]);

  return (
    <Tooltip title={sectionTitle}>
      <TextField
        inputRef={inputFieldRef}
        sx={textFieldStyles}
        variant="outlined"
        size="small"
        fullWidth
        data-testid="section-title-textfield"
        onChange={(e) => setSectionTitleState(e.target.value)}
        onKeyDown={blurOnEnter}
        inputProps={{ maxLength: 100, 'data-testid': 'section-title-textfield-input' }}
        placeholder={t('editor.default_content_section_title')}
        onBlur={(focusEvent) => {
          const newTitle = focusEvent.currentTarget.value;
          updateSectionTitleWS(newTitle);
        }}
        value={sectionTitle}
      />
    </Tooltip>
  );
};

export default SectionTitleInput;

import ReactSelect, { components } from 'react-select';
import ReactSelectAsync, { AsyncProps } from 'react-select/async';
import type { GroupBase, ThemeConfig } from 'react-select';
// @ts-expect-error does not detect the dist directory but it is there
import { type StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import Icon from 'components/icon';

import './styles.less';

const theme: ThemeConfig = (basethem) => ({
  ...basethem,
  colors: {
    ...basethem.colors,
    primary: 'var(--select-color-primary)',
    primary25: 'var(--select-color-primary25)',
    primary50: 'var(--select-color-primary50)',
  },
});

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="IcoClose" title="clear value" className="" />
    </components.ClearIndicator>
  );
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Icon name="IcoArrowDown" title="open" />
  </components.DropdownIndicator>
);

export function Select<
  Option = { label: string; value: unknown },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ components, ...props }: StateManagerProps<Option, IsMulti, Group>) {
  return (
    <ReactSelect
      {...props}
      components={{ ...components, ClearIndicator, DropdownIndicator }}
      theme={theme}
      className={`py-select ${props.className ?? ''}`}
      classNamePrefix="py-select"
    />
  );
}

export function AsyncSelect<
  Option = { label: string; value: unknown },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ components, ...props }: AsyncProps<Option, IsMulti, Group>) {
  return (
    <ReactSelectAsync
      {...props}
      components={{ ...components, ClearIndicator, DropdownIndicator }}
      theme={theme}
      className={`py-select py-select-async ${props.className ?? ''}`}
      classNamePrefix="py-select"
    />
  );
}

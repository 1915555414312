import { EmailPickerOption } from 'pages/proposals/share/blocks/form/email-picker';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { DocumentProspect } from 'services/documents/entities';
import { CreatableEmailPicker, CreatableEmailPickerProps } from '../creatable-email-picker';

import './styles.less';

interface ToProps extends Pick<CreatableEmailPickerProps, 'additionalData' | 'loadOptions'> {
  onShowCc: () => void;
  onShowBcc: () => void;
  viewBcc: boolean;
  viewCc: boolean;
  prospect?: DocumentProspect | null;
  onChange;
}

export function To({ onChange, loadOptions, additionalData, prospect, viewBcc, viewCc, onShowCc, onShowBcc }: ToProps) {
  const { t } = useTranslation();

  const [recipientValue, setRecipientValue] = useState<MultiValue<EmailPickerOption>>([]);

  useEffect(() => {
    if (prospect) {
      const prospectRecipient: EmailPickerOption = {
        email: prospect?.contact?.email || '',
        id: prospect?.contact?.id || null,
        label: prospect?.contact?.name || '',
        type: 'contact',
        value: prospect?.contact?.email || '',
        __isNew__: true,
      };
      setRecipientValue(prospect?.contact ? [prospectRecipient] : []);
    }
  }, [prospect]);

  const handleRecipientChange = (newRecipientValue: MultiValue<EmailPickerOption>) => {
    setRecipientValue(newRecipientValue);
  };

  useEffect(() => {
    onChange(recipientValue);
  }, [recipientValue, onChange]);

  return (
    <>
      {
        <CreatableEmailPicker
          value={recipientValue}
          onChange={handleRecipientChange}
          loadOptions={loadOptions}
          placeholder={''}
          additionalData={additionalData}
        />
      }

      {[viewBcc, viewCc].includes(false) && (
        <div className="extra-actions">
          {!viewCc && (
            <button className="cc_button" onClick={onShowCc}>
              {t('share.form.cc')}
            </button>
          )}
          {!viewBcc && (
            <button className="cc_button" onClick={onShowBcc}>
              {t('share.form.bcc')}
            </button>
          )}
        </div>
      )}
    </>
  );
}

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Editor } from '@tiptap/react';
import { Level } from '@tiptap/extension-heading';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import Icon from '../components/Icon';
import { ILabels } from '../types';
import { PyMuiButton, PyMuiMenu, PyMuiMenuItem } from '../components/generalComponents';
import IcoArrowDropDownRounded from '../../../../icon/icons/IcoArrowDropDownRounded';
import { fontSizes } from '../defaultStyles';

export const headingLevels: Level[] = [1, 2, 3, 4];

export const isHeadingActive = (editor: Editor) => headingLevels.some((level) => editor.isActive('heading', { level }));

const getSelectedLevel = (editor: Editor): Level | 0 => {
  if (isHeadingActive(editor)) {
    return editor.getAttributes('heading')?.level ?? 0;
  }
  return 0;
};

const styles = {
  heading: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    }),
  button: () =>
    css({
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    }),
};

type Props = {
  editor: Editor;
  headingLabels?: ILabels['headings'];
};

const Heading = ({ editor, headingLabels }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<Level | 0>(getSelectedLevel(editor));

  const selectedLabel = useMemo(() => {
    return headingLabels?.[`h${selected}`] || (selected ? `Heading ${selected}` : headingLabels?.normalText || 'Normal text');
  }, [selected, headingLabels]);

  useEffect(() => {
    setSelected(getSelectedLevel(editor));
  }, [editor]);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleSelectHeading = (level: Level) => {
    editor.chain().focus().toggleHeading({ level }).run();
    setSelected(level);
    handleCloseMenu();
  };

  const handleSelectNormalText = () => {
    editor.chain().focus().setParagraph().run();
    setSelected(0);
    handleCloseMenu();
  };

  return (
    <span css={styles.heading}>
      <PyMuiButton
        testId="heading-button"
        isMenuOpen={Boolean(anchorEl)}
        onClickAction={handleOpenMenu}
        isActive={isHeadingActive(editor)}
        width={144}
      >
        <span style={{ fontSize: 12, minWidth: 112, textAlign: 'left' }}>{selectedLabel}</span>
        <Icon>
          <IcoArrowDropDownRounded />
        </Icon>
      </PyMuiButton>
      <PyMuiMenu
        testId="heading-menu"
        id="select-heading-menu"
        menuListProps="select-heading-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleCloseMenu}
      >
        {headingLevels.map((level, index, arr) => (
          <PyMuiMenuItem
            testId={`heading-menu-item-${level}`}
            key={level}
            onClick={() => handleSelectHeading(level)}
            isActive={editor.isActive('heading', { level })}
            fontSize={fontSizes[level]}
            isFontWeightBold={level !== arr[arr.length - 1]}
            menuItem={headingLabels?.[`h${level}`] || `Heading ${level}`}
          />
        ))}
        <PyMuiMenuItem testId="normal-text" menuItem={headingLabels?.normalText || 'Normal text'} onClick={handleSelectNormalText} />
      </PyMuiMenu>
    </span>
  );
};

export default Heading;

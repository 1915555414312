import { FormControl, TextField, SxProps, Autocomplete, createFilterOptions } from '@mui/material';
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { MaskedInputProps } from '../billing/MaskedInputs';
import { SelectOptions } from '../billing/interface';
import { HTMLAttributes, ReactNode } from 'react';

interface AutocompleteFieldProps<T extends FieldValues> {
  id: string;
  label?: string;
  placeholder?: string;
  options: SelectOptions[];
  disabled?: boolean;
  hidden?: boolean;
  maskInputComponent?: React.ForwardRefExoticComponent<MaskedInputProps & React.RefAttributes<HTMLInputElement>>;
  control: Control<T>;
  rules?: Omit<RegisterOptions<T, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  helperText?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  sx?: SxProps;
  onUpdate?: (value: string) => void;
  freeSolo?: boolean;
  disableClearable?: boolean;
  selectOnFocus?: boolean;
  clearOnBlur?: boolean;
  handleHomeEndKeys?: boolean;
  renderOption?: (props: HTMLAttributes<HTMLLIElement> & { key: any }, option: SelectOptions) => ReactNode;
  inputValue?: string;
  onInputChange?: (event: React.SyntheticEvent<Element, Event>, value: string) => void;
}

const filter = createFilterOptions<SelectOptions>({
  stringify: (option) => `${option.name} ${option.secondary || ''}`.toLowerCase(),
});

function AutocompleteField<T extends FieldValues>(props: AutocompleteFieldProps<T>) {
  const {
    id,
    label,
    placeholder = '',
    options,
    control,
    rules,
    sx,
    disabled = false,
    freeSolo = true,
    disableClearable,
    selectOnFocus = true,
    clearOnBlur = false,
    handleHomeEndKeys = true,
    renderOption,
    inputValue,
    onInputChange,
  } = props;

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl key={`form-control-${id}`} error={!!error} sx={{ flex: '1 0 0', overflow: 'visible', ...sx }}>
          <Autocomplete
            disableClearable={disableClearable}
            freeSolo={freeSolo}
            id={id}
            options={options}
            selectOnFocus={selectOnFocus}
            clearOnBlur={clearOnBlur}
            handleHomeEndKeys={handleHomeEndKeys}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Regular option
              return option.name || option.secondary || '-';
            }}
            renderInput={(params) => (
              <TextField {...params} label={label} placeholder={placeholder} error={!!error} helperText={error?.message} />
            )}
            renderOption={renderOption ? renderOption : (props, option) => <li {...props}>{option.name}</li>}
            filterOptions={(options, params) => filter(options, params)}
            onChange={(e, data) => {
              onChange(data);
            }}
            inputValue={inputValue}
            onInputChange={(e, data) => {
              onChange(data);
              if (onInputChange) {
                onInputChange(e, data);
              }
            }}
            disabled={disabled}
            defaultValue={null}
          />
        </FormControl>
      )}
    />
  );
}

export default AutocompleteField;

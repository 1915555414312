import { GridRowId } from '@mui/x-data-grid-pro';

import TextEditor from './TextEditor';
import { RootState, useAppSelector } from '../../../grid/reduxStore/Store';
import { selectContentTableRows } from '../../../grid/reduxStore/editorSlice';

interface CustomTableCellProps {
  id: GridRowId;
  hasFocus?: boolean;
  field: string;
  blockId?: string;
  sectionId?: string;
  numberOfDecimalPlaces?: number;
}

export const TipTapRenderCell = ({ id, field, blockId, sectionId }: CustomTableCellProps) => {
  const rowData = useAppSelector((state: RootState) => selectContentTableRows(state, sectionId as string, blockId as string))?.find(
    (row) => row.id === id
  );
  if (!id) {
    return <></>;
  }
  const valueDisplay = rowData?.[field];

  return (
    <div className={`editable-cell-wrapper editable-cell-${id}`}>
      <TextEditor editable={true} value={valueDisplay} field={field} id={id as GridRowId} />
    </div>
  );
};

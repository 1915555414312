import { IconButton } from '@mui/material';
import { css } from '@emotion/react';
import { TOOLBAR_COLORS, TOOLBAR_DIMENSION, TOOLBAR_SPACING, TOOLBAR_STATE } from '../../helpers/toolbarStyleSettings';

const getBackgroundColor = (active?: boolean) => {
  if (active) {
    return {
      backgroundColor: TOOLBAR_COLORS.focusColor,
      color: TOOLBAR_COLORS.primary,
    };
  }
  return TOOLBAR_STATE;
};

const classes = {
  buttonIcon: ({ isActive, outlinedColorWhenActive }: { isActive?: boolean; outlinedColorWhenActive?: string }) => {
    return css({
      borderRadius: TOOLBAR_SPACING.borderRadius,
      padding: '4px',
      height: TOOLBAR_DIMENSION.height,
      width: TOOLBAR_DIMENSION.height,
      ...getBackgroundColor(isActive && !outlinedColorWhenActive),
      ':enabled': {
        color: outlinedColorWhenActive && isActive ? outlinedColorWhenActive : TOOLBAR_COLORS.secondary,
      },
    });
  },
};

interface SpMuiIconButtonProps {
  isActive?: boolean;
  outlinedColorWhenActive?: string;
  attributeName?: string;
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

const PyMuiIconButton = ({
  onClick,
  disabled,
  children,
  isActive,
  attributeName,
  outlinedColorWhenActive = undefined,
}: SpMuiIconButtonProps) => {
  return (
    <IconButton
      data-testid={`toolbar-${attributeName}-button`}
      disabled={disabled}
      onClick={onClick}
      sx={classes.buttonIcon({ isActive: isActive, outlinedColorWhenActive })}
    >
      {children}
    </IconButton>
  );
};

export default PyMuiIconButton;

import * as React from 'react';
const SvgIcoHighlight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.6 14 11 11.4l-5 5L8.6 19zm-1.175-4.025 2.6 2.6L20 7.6 17.4 5zm-2.1-.7 5.4 5.4L10 20.4q-.6.6-1.4.6t-1.4-.6l-.05-.05-.075.075a1.976 1.976 0 0 1-1.4.575H2.7q-.35 0-.475-.3t.125-.55l2.3-2.3-.05-.05q-.6-.6-.6-1.4t.6-1.4zm0 0L16 3.6q.6-.6 1.4-.6t1.4.6l2.6 2.6q.6.6.6 1.4T21.4 9l-5.675 5.675z"
      fill="currentColor"
      fillOpacity={0.56}
    />
  </svg>
);
export default SvgIcoHighlight;

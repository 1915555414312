import { Trans, useTranslation } from 'react-i18next';
import SeatsAddForm from './SeatsAddForm';
import MultiStepDialog from 'components/MultiStepDialog/MultiStepDialog';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MultiDialogStep } from 'components/MultiStepDialog/interface';
import { AddSeatsEntity, PreviewBasicApiResponse } from 'services/api/seats/interfaces';
import { PlanSummaryContext } from './PlanSummaryProvider';
import usePreviewAddSeatsMutation from 'services/api/seats/hooks/usePreviewAddSeatsMutation';
import useAddSeatsMutation from 'services/api/seats/hooks/useAddSeatsMutation';
import SeatsAddConfirmation from './SeatsAddConfirmation';
import { Link } from '@mui/material';

interface SeatsAddModalProps {
  seats: { usedCollaboratorSeats: number | undefined; userSeats: number };
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  onSuccess: () => void;
}

const renderLink = (href) => <Link href={href} rel="noopener" target="_blank" color="text.primary" variant="subtitle1" />;

const SeatsAddModal = ({ seats, isOpen, setIsOpen, onSuccess }: SeatsAddModalProps) => {
  const { t } = useTranslation();
  const [seatsToAdd, setSeatsToAdd] = useState<AddSeatsEntity>({
    accountId: -1,
    collaboratorSeats: 0,
    userSeats: 0,
    usedCollaboratorSeats: seats.usedCollaboratorSeats || 0,
  });
  const [previewBillingData, setPreviewBillingData] = useState<PreviewBasicApiResponse>();

  const { setNotifications } = useContext(PlanSummaryContext);
  const { previewAddSeats, isLoading: isLoadingPreview } = usePreviewAddSeatsMutation();
  const { addSeats, isLoading: isLoadingAdd } = useAddSeatsMutation();

  useEffect(() => {
    setSeatsToAdd({
      accountId: -1,
      collaboratorSeats: 0,
      userSeats: seats.userSeats,
      usedCollaboratorSeats: seats.usedCollaboratorSeats || 0,
    });
  }, [seats]);

  const onNextStep = async () => {
    const response = await previewAddSeats({ payload: seatsToAdd });
    if (!response.state) {
      setNotifications([{ content: t(response.message), severity: 'error' }]);
    }
    setPreviewBillingData(response);
  };

  const onCharge = async () => {
    const response = await addSeats({ payload: seatsToAdd });
    if (!response.state) {
      setNotifications([
        {
          content: (
            <Trans i18nKey="settings.summary.seat_add.charge_error" components={{ support: renderLink('mailto:support@proposify.com') }} />
          ),
          severity: 'error',
        },
      ]);
      return;
    }
    onSuccess();
  };

  const steps: MultiDialogStep[] = useMemo(() => {
    const newSteps: MultiDialogStep[] = [];
    newSteps.push({
      title: 'settings.summary.seat_add.title',
      content: (
        <SeatsAddForm
          onSuccess={(payload: AddSeatsEntity) => {
            setSeatsToAdd(payload);
          }}
          data={seatsToAdd}
        />
      ),
      actions: [
        {
          isNextStep: true,
          label: 'settings.summary.seat_add.continue',
          variant: 'contained',
          isDisabled: seatsToAdd.collaboratorSeats === 0 && seatsToAdd.userSeats === 0,
        },
      ],
    });
    newSteps.push({
      title: 'settings.summary.seat_add.review_order',
      content: <SeatsAddConfirmation previewBillingData={previewBillingData} isLoading={isLoadingPreview} />,
      actions: [
        { isBack: true, label: 'back', variant: 'text', isDisabled: isLoadingAdd || isLoadingPreview },
        {
          onClick: () => onCharge(),
          label: 'settings.summary.seat_add.add_seats',
          variant: 'contained',
          isDisabled: seatsToAdd.accountId === -1 || isLoadingAdd || isLoadingPreview,
          showSpinner: isLoadingAdd,
        },
      ],
    });
    return newSteps;
  }, [seatsToAdd, previewBillingData, isLoadingPreview, isLoadingAdd]);

  return (
    <MultiStepDialog
      isOpen={isOpen}
      setModalOpen={setIsOpen}
      steps={steps}
      isCloseDisabled={isLoadingAdd || isLoadingPreview}
      onBeforeNewStep={(oldStep, newStep) => {
        if (newStep === 1) {
          onNextStep();
        }
        return true;
      }}
    />
  );
};

export default SeatsAddModal;

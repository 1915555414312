import { FunctionComponent, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
  condition: boolean;
  isLoading?: boolean;
}

const NavigateToCinder = () => {
  useEffect(() => {
    window.location.href = `${globalThis.env.VITE_LEGACY_APP_URL}/dashboard`;
  }, []);

  return null;
};

const ProtectedRoutes: FunctionComponent<Props> = ({ condition, isLoading }) => {
  if (isLoading) return <></>;
  return condition ? <Outlet /> : <NavigateToCinder />;
};

export default ProtectedRoutes;

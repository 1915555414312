import { useMutation } from 'react-query';
import { ApiSeatsRepository } from 'services/api/seats/ApiSeatsRepository';
import { BasicApiResponse, ResendInviteEntity } from 'services/api/seats/interfaces';

type ResendInviteParam = {
  accountId: number;
  payload: ResendInviteEntity;
};

export default function useResendInviteMutation() {
  const reactQuery = useMutation({
    mutationFn: resendInvite,
  });

  return { resendInvite: reactQuery.mutateAsync, resendInvitePromise: reactQuery.mutate, isLoading: reactQuery.isLoading };
}

const resendInvite = async (data: ResendInviteParam): Promise<BasicApiResponse> => {
  const seatsApi = new ApiSeatsRepository();
  const response = await seatsApi.resendInvite(data.payload);

  return {
    state: response.state,
    message: response.message,
  };
};

import { useState } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { FlexDataLinkRepository } from 'services/flex-data/repositories/FlexDataLinkRepository';
import { FlexDataLink, FlexDataLinkResponse } from './types';

export const useSearchFlexDataLinks = (dataSourceType: string, tenantId: string, objectType: string, keywords: string) => {
  const [flexDataLinkOptions, setFlexDataLinkOptions] = useState<FlexDataLink[]>([]);

  const { isLoading, isError, status } = useQuery({
    queryKey: [QueryKeys.searchFlexDataLinks, { dataSourceType, tenantId, objectType, keywords }],
    queryFn: () => FlexDataLinkRepository.searchFlexDataLinks(dataSourceType, tenantId, objectType, keywords),
    onSuccess: (data: FlexDataLinkResponse) => {
      setFlexDataLinkOptions(data.data);
    },
    enabled: !!keywords,
    keepPreviousData: true,
  });

  return {
    isFlexDataLinkOptionsLoading: isLoading,
    flexDataLinkOptions,
    isFlexDataLinkOptionsError: isError,
    status,
  };
};

import { UserEntity } from 'services/entities/UserEntity';
import userflow from 'userflow.js';
import { isProduction } from 'utils/getEnvironment';

if (isProduction()) {
  userflow.init(globalThis.env.VITE_USERFLOW_TOKEN ?? '');
}

type UserflowUser = Pick<UserEntity, 'id' | 'email' | 'fullName'>;

export async function setUserflowUser({ id, email, fullName }: UserflowUser) {
  if (!isProduction()) return;

  await userflow.identify(id.toString(), {
    email,
    name: fullName,
  });
}

export function useUserflow() {
  const setUser = async (user: UserflowUser) => {
    if (user.id && user.email && user.fullName) {
      await setUserflowUser(user);
    }
  };

  return { setUser };
}

import React, { MutableRefObject, useEffect, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material';
import { SIZES } from '../../../../../../muiTheme/MuiDataGrid';
import { useTranslation } from 'react-i18next';
import { trimLeadingZeros } from 'components/editor/helpers/trimLeadingZeros';

type InputWithToolTipsFooterProps = Partial<GridRenderEditCellParams> & {
  tooltipTitle?: string | null;
  onInputChange?: (value: string) => void;
  children?: React.ReactNode;
  rowType: string;
  value: number | string;
  rowId: string;
  inputRefs: MutableRefObject<Record<string, any>>;
  inputType?: 'number' | 'text';
  isHidden?: boolean;
};

const TotalRowToolTip = styled('span')<{
  isHidden: boolean;
}>(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  fontSize: '1rem',
  paddingRight: 0,
  input: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: 'right',
    lineHeight: SIZES.defaultRowHeight,
    width: '100%',
    backgroundColor: 'transparent',
    MozAppearance: 'textfield',
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

export const TooltipInputRedux: React.FC<InputWithToolTipsFooterProps> = ({
  rowId,
  inputRefs,
  onInputChange,
  children,
  value,
  isHidden = false,
  inputType,
}) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);
  const tooltipTitle = isHidden && t('document.grid.table.cell_hidden_tooltip');

  const handleOnChange = async (value: string) => {
    setLocalValue(value);
    onInputChange?.(value);
  };

  const handleOnBlur = async () => {
    const trimmedValue = trimLeadingZeros(localValue);
    setLocalValue(trimmedValue);
    onInputChange?.(trimmedValue);
  };

  useEffect(() => {
    if (value?.toString() !== localValue) {
      setLocalValue(value?.toString() ?? '');
    }
  }, [value]);

  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <TotalRowToolTip isHidden={isHidden}>
        <input
          ref={(el) => (inputRefs.current[rowId] = el)}
          className="py-custom-input"
          type={inputType}
          onChange={(e) => handleOnChange(e.target.value)}
          onBlur={() => handleOnBlur()}
          data-testid="input-with-tooltips"
          value={localValue}
          style={{ fontWeight: '600' }}
        />
        {children}
      </TotalRowToolTip>
    </Tooltip>
  );
};

export default TooltipInputRedux;

import * as React from 'react';
import { Props } from './interface';
import { Helmet } from 'react-helmet';
import { useInstalledFonts } from '../../hooks/useInstalledFonts';
import { useEffect } from 'react';
import { FontEntity } from '../../services/entities/FontsEntity';
import { defaultFont } from '../editor/helpers/config';

const fontCdnDomain = globalThis.env.VITE_CI_STORAGE_CDN_FONTS || undefined;

const fontComparator = (font1: FontEntity, font2: FontEntity): number => {
  const font1Name = font1.name.toUpperCase();
  const font2Name = font2.name.toUpperCase();
  if (font1Name < font2Name) {
    return -1;
  } else if (font1Name > font2Name) {
    return 1;
  } else return 0;
};

const FontLoader: React.FunctionComponent<Props> = ({ editorConfigHandler, contentId, fontsFetchedCb }) => {
  const { result: installedFonts, isFetched } = useInstalledFonts(contentId);

  useEffect(() => {
    if (!installedFonts || !isFetched) return;
    const fontsSorted = {};
    const fontNames: Set<string> = new Set();
    installedFonts.sort(fontComparator).map((font: FontEntity) => {
      fontNames.add(font.name);
      return font;
    });
    defaultFont.map((font) => {
      fontNames.add(font.name);
    });
    Array.from(fontNames)
      .sort()
      .forEach((fontName) => {
        fontsSorted[fontName] = defaultFont[fontName] ?? fontName;
      });

    const newConfig = {
      fontFamily: fontsSorted,
      fontFamilySelection: true,
    };
    editorConfigHandler(newConfig);

    if (fontsFetchedCb) {
      fontsFetchedCb();
    }
  }, [isFetched]);

  return (
    <Helmet>
      {fontCdnDomain &&
        installedFonts &&
        installedFonts
          .filter((font) => !(!font.path || font.path === ''))
          .map((font) => {
            const relation = 'stylesheet';
            const cssUrl = `${fontCdnDomain}/font/${font.path}/styles.css`;
            return <link key={font.id} href={cssUrl} rel={relation} />;
          })}
    </Helmet>
  );
};

export default FontLoader;

import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {
  useDocumentInviteForm,
  SubmitHandler,
  transformDocumentInviteFieldValuesToApiPayload,
  DocumentInviteFormFieldValues,
} from './useDocumentInviteForm';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useDocumentInviteMutation from 'services/api/seats/hooks/useDocumentInviteMutation';
import { NotificationsContext } from 'components/notifications/NotificationsProvider';
import { useCurrentUser } from 'providers/UserProvider';
import { useDocumentCollaboratorsApiData } from 'services/api/seats/hooks/useDocumentCollaboratorsApiData';
import { useSeatsApiData } from 'services/api/seats/hooks/useSeatsApiData';
import useDocumentRemoveAccessMutation from 'services/api/seats/hooks/useDocumentRemoveAccessMutation';
import { Person } from '@mui/icons-material';
import AutocompleteField from 'pages/settings/utils/AutocompleteField';
import { SelectOptions } from 'pages/settings/billing/interface';
import { isValidEmail } from 'utils/isValidEmail';
import { getInitials } from 'utils/getInitials';
import { Link } from 'react-router-dom';

interface DocumentInviteFormProps {
  documentId: string;
  onSuccess?: () => void;
}

const DocumentInviteForm = ({ documentId, onSuccess }: DocumentInviteFormProps) => {
  const { t } = useTranslation();
  const [currentlyRemoving, setCurrentlyRemoving] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const {
    data: { accountId, id: userId },
  } = useCurrentUser();

  const { data: seatsInfo, isLoading: isLoadingSeats } = useSeatsApiData({ accountId });

  const {
    data: documentCollaborators,
    isLoading: isLoadingDocumentCollaborators,
    refetch: refetchDocumentCollaborators,
    isRefetching,
  } = useDocumentCollaboratorsApiData({
    documentId: documentId,
  });

  const { setNotifications } = useContext(NotificationsContext);

  const { documentInvite, isLoading: isUpdating } = useDocumentInviteMutation();

  const { handleSubmit, control, setError, setValue, setFocus } = useDocumentInviteForm({
    email: null,
    accountId: accountId,
    documentId: documentId,
  });

  const { documentRemoveAccess, isLoading: isRemoving } = useDocumentRemoveAccessMutation();

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      const payload = transformDocumentInviteFieldValuesToApiPayload(submitData);
      if (payload.email && !isValidEmail(payload.email)) {
        setError('email', { type: 'custom', message: t('document_invite.form.invalid_email') }, { shouldFocus: true });
        return;
      }

      if (payload.email && documentCollaborators) {
        if (documentCollaborators.findIndex((collab) => collab.email === payload.email) > -1) {
          setError('email', { type: 'custom', message: t('document_invite.form.already_invited') }, { shouldFocus: true });
          return;
        }
      }

      if (payload.email && seatsInfo?.occupiedSeats) {
        const found = seatsInfo.occupiedSeats.find((seat) => seat.email === payload.email);
        if (found) {
          payload.userId = found.userId;
        }
      }

      const response = await documentInvite({ payload: payload });
      if (!response.state) {
        setError(
          'email',
          <Trans
            i18nKey="document_invite.form.submission_error"
            components={{ support: <Link rel="noopener" target="_blank" to="mailto:support@proposify.com" /> }}
          />
        );
        return;
      }
      refetchDocumentCollaborators();

      setValue('email', null);
      setInputValue('');
      if (onSuccess) {
        onSuccess();
      }
    },
    [
      transformDocumentInviteFieldValuesToApiPayload,
      documentInvite,
      onSuccess,
      accountId,
      seatsInfo,
      documentCollaborators,
      setValue,
      setError,
    ]
  );

  const handleRemoveAccess = useCallback(
    async (userId: number) => {
      setCurrentlyRemoving(userId);
      const response = await documentRemoveAccess({
        payload: {
          userId,
          accountId,
          documentId,
        },
      });

      if (!response.state) {
        setNotifications({ content: response.message, severity: 'error' });
        return;
      }

      refetchDocumentCollaborators();

      setNotifications({ content: t('document_invite.form.collaborators_with_access.remove_success'), severity: 'success' });
    },
    [documentRemoveAccess, accountId]
  );

  const isLoadingData = useMemo<boolean>(() => {
    return isUpdating || isLoadingDocumentCollaborators || isRefetching || isLoadingSeats;
  }, [isUpdating, isLoadingDocumentCollaborators, isRefetching, isLoadingSeats]);

  const noRemainingCollaboratorSeats = useMemo<boolean>(() => {
    if (!seatsInfo) {
      return false;
    }
    return seatsInfo.collaborators.empty === 0;
  }, [seatsInfo]);

  const autocompleteOptions = useMemo<SelectOptions[]>(() => {
    const occupiedSeats = seatsInfo?.occupiedSeats ?? [];
    const collaboratorsSet = new Set(
      Array.isArray(documentCollaborators) ? documentCollaborators.map((dc) => `${dc.userId}-${dc.email}`) : []
    );

    return occupiedSeats
      .filter(({ userId, email }) => !collaboratorsSet.has(`${userId}-${email}`) && userId !== userId)
      .map(({ userId, name, email }) => ({
        id: userId.toString(),
        name,
        secondary: email,
      }));
  }, [seatsInfo?.occupiedSeats, documentCollaborators, userId]);

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} data-testid="document-invite-form">
      {noRemainingCollaboratorSeats && <Alert severity="info">{t('document_invite.form.no_empty_seats')}</Alert>}
      <Stack direction="column" sx={{ pt: '5px' }} gap={3}>
        <Stack direction="row" gap={1} justifyContent="space-between" alignContent="center">
          <AutocompleteField<DocumentInviteFormFieldValues>
            id="email"
            label={t('document_invite.form.email_label')}
            placeholder={t('document_invite.form.email_placeholder')}
            control={control}
            options={autocompleteOptions}
            disabled={isLoadingData}
            rules={{
              required: t('required_field'),
            }}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <ListItem key={`document-invite-option-${option.id}-${key}`} disableGutters {...optionProps}>
                  <ListItemAvatar>
                    <Avatar alt="avatar" variant="circular">
                      {option.id === '-1' ? <Person /> : `${getInitials(option.name)}`}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={option.id === '-1' ? `${t('document_invite.form.custom_option_label')}: ${option.name}` : option.name}
                    secondary={option.secondary}
                  />
                </ListItem>
              );
            }}
            inputValue={inputValue}
            onInputChange={(e, value) => {
              setInputValue(value);
            }}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isLoadingData}
            data-testid="document-invite-form-submit"
            sx={{
              minWidth: '88px',
              padding: '8px 22px',
              alignSelf: 'flex-start',
              marginTop: '6px',
            }}
          >
            {isUpdating ? <CircularProgress color="info" size="22px" /> : t('invite')}
          </Button>
        </Stack>
      </Stack>

      <Stack direction="column" alignContent="center" gap={1} mt={3}>
        {documentCollaborators && documentCollaborators.length > 0 ? (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">{t('document_invite.form.collaborators_with_access.title')}</Typography>
            <Typography variant="caption" color="text.secondary">
              {t('document_invite.form.collaborators_with_access.of_used', {
                used: seatsInfo?.collaborators?.used || '0',
                total: seatsInfo?.collaborators?.total || '?',
              })}
            </Typography>
          </Stack>
        ) : null}
        <List sx={{ overflow: 'auto', maxHeight: '300px' }}>
          {documentCollaborators &&
            documentCollaborators.length > 0 &&
            documentCollaborators.map((user) => (
              <ListItem
                disableGutters
                key={`document-collaborator-${user.userId.toString()}`}
                secondaryAction={
                  <Button variant="text" disabled={isLoadingData} onClick={() => handleRemoveAccess(user.userId)}>
                    {isRemoving && currentlyRemoving === user.userId ? (
                      <CircularProgress color="info" size="22px" />
                    ) : (
                      t('document_invite.form.collaborators_with_access.remove_access')
                    )}
                  </Button>
                }
              >
                <ListItemAvatar>
                  <Avatar alt="avatar" variant="circular">
                    {getInitials(user.firstName, user.lastName) || <Person />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    user.firstName ? (
                      `${user.firstName} ${user.lastName}`
                    ) : (
                      <Tooltip title={user.email}>
                        <span>
                          {user.email} ({t('pending').toLowerCase()})
                        </span>
                      </Tooltip>
                    )
                  }
                  secondary={user.firstName ? user.email : null}
                  primaryTypographyProps={{ color: user.firstName ? 'text.primary' : 'text.secondary' }}
                />
              </ListItem>
            ))}
        </List>
      </Stack>
    </Box>
  );
};

export default DocumentInviteForm;

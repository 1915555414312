import { useCallback } from 'react';
import { ColumnTypeIdentifier, RowTypesIdentifier, TableColumnType } from '../../../grid/reduxStore/table.types';
import { isRowBelongsToTotal } from '../../../../../muiTheme/dataGridUtils';
import { NUMBER_COLUMNS_ALLOWING_PRICING } from './variables';
import CellInputs from './CellInput';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { CustomTableCell } from './components/CustomTableCell';
import { TableNumericCell } from './tableUtils/customCellHelpers';
import { isNumericColumn, shouldRoundPricingCell } from './helper';

import { TipTapRenderEditCell } from '../../TiptapEditor/components/TipTapRenderEditCell';
import useFeatureFlag from '../../../../../hooks/useFeatureFlag';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { TipTapRenderCell } from '../../TiptapEditor/components/TipTapRenderCell';

export const isEligibleForStyling = (rowType: RowTypesIdentifier, columnType: ColumnTypeIdentifier, isTextStylingActive: boolean) => {
  return (
    (isTextStylingActive && [RowTypesIdentifier.FOOTER, RowTypesIdentifier.HEADER].includes(rowType)) ||
    (rowType === RowTypesIdentifier.BODY && columnType === ColumnTypeIdentifier.TEXT)
  );
};

const renderEditableCustomCell = (
  params: GridCellParams,
  columnType: ColumnTypeIdentifier,
  isTextStylingActive: boolean,
  sectionId: string,
  blockId: string,
  pricingDecimalPlaces: number
) => {
  const isStylingApplicable = isEligibleForStyling(params.row.rowType, columnType, isTextStylingActive);

  if (shouldRoundPricingCell(columnType)) {
    return <CellInputs params={params} blockId={blockId} numberOfDecimalPlaces={pricingDecimalPlaces} sectionId={sectionId} />;
  }

  if (isStylingApplicable && columnType !== ColumnTypeIdentifier.OPTIONAL) {
    return <TipTapRenderEditCell field={params.field} hasFocus={params.hasFocus} id={params.id} />;
  }

  return <CellInputs params={params} blockId={blockId} sectionId={sectionId} />;
};

const renderCustomCell = (
  params: GridCellParams,
  columnType: ColumnTypeIdentifier,
  isTextStylingActive: boolean,
  sectionId: string,
  blockId: string,
  pricingDecimalPlaces: number
) => {
  const isStylingApplicable = isEligibleForStyling(params.row.rowType, columnType, isTextStylingActive);

  if (shouldRoundPricingCell(columnType)) {
    return <TableNumericCell params={params} columnType={columnType} numberOfDecimalPlaces={pricingDecimalPlaces} />;
  }

  if (isNumericColumn(columnType) && !isStylingApplicable) {
    return <TableNumericCell params={params} columnType={columnType} />;
  }

  if (isStylingApplicable && columnType !== ColumnTypeIdentifier.OPTIONAL) {
    return (
      <TipTapRenderCell
        id={params.id}
        field={params.field}
        blockId={blockId}
        sectionId={sectionId}
        numberOfDecimalPlaces={pricingDecimalPlaces}
        hasFocus={params.hasFocus}
      />
    );
  }

  return <CustomTableCell params={params} value={params?.value as string} />;
};

const applyColSpanRules = (columns: TableColumnType[]): TableColumnType[] => {
  const firstColumnIndex = 1;

  columns[firstColumnIndex] = {
    ...columns[firstColumnIndex],
    colSpan: (_value, row): number => {
      if (row.rowType === RowTypesIdentifier.FOOTER) {
        return columns.length - 1;
      }
      if (isRowBelongsToTotal(row)) {
        return columns.length > NUMBER_COLUMNS_ALLOWING_PRICING ? columns.length - NUMBER_COLUMNS_ALLOWING_PRICING : 1;
      }
      return 1;
    },
  };

  return columns;
};

export function useApplyCustomRulesToColumns(sectionId: string, blockId: string, pricingDecimalPlaces: number) {
  const isTextStylingActive = useFeatureFlag([FeatureFlags.spTextStyling])[FeatureFlags.spTextStyling];

  const applyCustomRendering = useCallback(
    (columns: TableColumnType[]): TableColumnType[] => {
      return columns.map((column) => ({
        ...column,
        renderEditCell: (params: GridCellParams) =>
          renderEditableCustomCell(params, column.columnType, isTextStylingActive, sectionId, blockId, pricingDecimalPlaces),
        renderCell: (params: GridCellParams) =>
          renderCustomCell(params, column.columnType, isTextStylingActive, sectionId, blockId, pricingDecimalPlaces),
      }));
    },
    [isTextStylingActive, sectionId, blockId, pricingDecimalPlaces]
  );

  const applyCustomRulesToColumns = useCallback(
    (columns: TableColumnType[]): TableColumnType[] => applyColSpanRules(applyCustomRendering(columns)),
    [applyCustomRendering]
  );

  return { applyCustomRulesToColumns };
}

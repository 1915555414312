import * as React from 'react';
const SvgIcoArrowDropDownRounded = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m8.71 9.71 2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71"
      fill="currentColor"
      fillOpacity={0.56}
    />
  </svg>
);
export default SvgIcoArrowDropDownRounded;

import Cookies from 'js-cookie';

const cookieDomain = globalThis.env.VITE_COOKIE_DOMAIN;
const rootDomain = cookieDomain ? '.' + cookieDomain : '.' + location.hostname.split('.').slice(1).join('.');
const getCookieData = (expires) => {
  return {
    expires: expires,
    path: '/',
    domain: rootDomain,
  };
};

interface AuthCookieServiceProps {
  getAccessToken(): string | null;
  setAccessToken(token: string, expires): void;
  getRefreshToken(): string | null;
  setRefreshToken(token: string, expires): void;
}

export const AuthCookieService: AuthCookieServiceProps = {
  getAccessToken() {
    return Cookies.get('frontend_token');
  },

  setAccessToken(token, expires) {
    Cookies.set('frontend_token', token, getCookieData(expires));
  },

  getRefreshToken() {
    return Cookies.get('frontend_refresh_token');
  },

  setRefreshToken(token, expires) {
    Cookies.set('frontend_refresh_token', token, getCookieData(expires));
  },
};

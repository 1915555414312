import { Box } from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export default function ViewedTimelineChart() {
  const mockedData = [
    { date: 'Jul 31', Joe: 1, Greg: 1, Sarah: 0 },
    { date: 'Aug 1', Joe: 0, Greg: 0, Sarah: 1 },
    { date: 'Aug 2', Joe: 4, Greg: 0, Sarah: 2 },
    { date: 'Aug 3', Joe: 0, Greg: 0, Sarah: 0 },
    { date: 'Aug 4', Joe: 0, Greg: 0, Sarah: 0 },
    { date: 'Aug 5', Joe: 1, Greg: 3, Sarah: 2 },
    { date: 'Aug 6', Joe: 0, Greg: 1, Sarah: 0 },
  ];

  const nameMappings = {
    Joe: 'Joe Wilkinson',
    Greg: 'Greg Davis',
    Sarah: 'Sarah Pascoe',
  };

  return (
    <Box data-testid="viewed-timeline-chart">
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={mockedData}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip formatter={(value, name) => [`${name} viewed ${value} times`]} />
          <Legend iconType="circle" iconSize={10} formatter={(value) => nameMappings[value]} />
          <Line type="linear" dataKey="Joe" stroke="#7987FF" dot={false} strokeWidth={2} />
          <Line type="linear" dataKey="Greg" stroke="#E697FF" dot={false} strokeWidth={2} />
          <Line type="linear" dataKey="Sarah" stroke="#FFA5CB" dot={false} strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

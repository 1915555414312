import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

export type LineHeightOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lineHeight: {
      /**
       * Set the line height
       */
      setLineHeight: (lineHeight: string) => ReturnType;
      /**
       * Unset the line height
       */
      unsetLineHeight: () => ReturnType;
    };
  }
}

export const LineHeight = Extension.create<LineHeightOptions>({
  name: 'lineHeight',
  addOptions() {
    return {
      types: ['paragraph', 'heading'],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: null,
            parseHTML: (element) => element.style.lineHeight,
            renderHTML: (attributes) => {
              if (!attributes.lineHeight || attributes.dataPasted) {
                return {};
              }

              return {
                style: `line-height: ${attributes.lineHeight}`,
              };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setLineHeight: (lineHeight: string) => {
        return ({ chain, editor }) => {
          const activeType = editor.isActive('heading') ? 'heading' : 'paragraph';
          return chain().updateAttributes(activeType, { lineHeight }).run();
        };
      },
      unsetLineHeight: () => {
        return ({ chain, editor }) => {
          const activeType = editor.isActive('heading') ? 'heading' : 'paragraph';
          return chain().updateAttributes(activeType, { lineHeight: null }).run();
        };
      },
    };
  },
});

/** @jsxImportSource @emotion/react */
import { EditorContent } from '@tiptap/react';
import { useContext, useEffect } from 'react';
import { useTextEditor } from '../hooks/useTextEditor';
import { ActiveEditorContext } from '../ActiveEditorContext';

import { TextEditorProps } from '../types';
import { defaultStyle } from '../defaultStyles';
import { Global } from '@emotion/react';

const TextEditor = ({
  customCss,
  placeholder,
  onChange,
  inputClassName,
  value,
  rootClassName,
  userPathname,
  uploadFileOptions,
  isPreview = false,
  editable = true,
  ...editorOptions
}: TextEditorProps) => {
  const { setActiveEditor } = useContext(ActiveEditorContext);

  const editor = useTextEditor({
    placeholder,
    onChange,
    value,
    tab: 'editor',
    editable,
    userPathname,
    uploadFileOptions,
    parseOptions: {
      preserveWhitespace: 'full',
    },
    ...editorOptions,
  });

  useEffect(() => {
    if (editable && !isPreview && editor) {
      setActiveEditor(editor);
    }
  }, [editor, setActiveEditor, editable]);

  if (!editable) {
    return <EditorContent editor={editor} className={inputClassName} />;
  }

  return (
    <div className={rootClassName} css={{ ...customCss }}>
      <Global styles={defaultStyle} />
      <EditorContent editor={editor} className={inputClassName} />
    </div>
  );
};

export default TextEditor;

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Close from '@mui/icons-material/Close';
import useRemoveSeatMutation from 'services/api/seats/hooks/useRemoveSeatMutation';
import { useContext, useMemo, useState } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { ErrorOutline } from '@mui/icons-material';

interface SeatsRemoveModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  onSuccess: () => void;
  userId?: number;
  userName?: string;
  seatType: 'user' | 'collaborator';
  currentTotalSeats: number;
}

const SeatsRemoveModal = ({ isOpen, setIsOpen, onSuccess, userId, userName, seatType, currentTotalSeats }: SeatsRemoveModalProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');
  const { accountId } = useContext(PlanSummaryContext);
  const { removeSeat, isLoading } = useRemoveSeatMutation();

  const onConfirmRemove = async () => {
    setError('');
    const response = await removeSeat({
      accountId,
      payload: {
        accountId,
        seatType,
        userId: userId || null,
      },
    });
    if (!response.state) {
      setError(response.message);
      return;
    }
    onSuccess();
  };

  const remaining = useMemo(() => currentTotalSeats - 1, [currentTotalSeats, seatType]);
  const pluralSeatType = useMemo(() => (remaining === 1 ? `${seatType} seat` : `${seatType} seats`), [remaining, seatType]);
  const dialogText = useMemo(() => {
    if (!userName) {
      return t('settings.summary.seat_remove.are_you_sure', { remaining: remaining.toString(), seatType: pluralSeatType });
    }
    return t('settings.summary.seat_remove.are_you_sure_with_name', {
      name: userName,
      remaining: remaining.toString(),
      seatType: pluralSeatType,
    });
  }, [userName, seatType, remaining]);
  return (
    <Dialog open={isOpen} data-testid="seat-remove-modal">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('settings.summary.seat_remove.title', { name: userName || 'empty seat' })}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpen(false);
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
              padding: '5px',
            })}
            data-testid="seat-remove-modal-close"
          >
            <Close sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{dialogText}</Typography>

        {error && (
          <Alert sx={{ mt: 1 }} icon={<ErrorOutline />} severity="error" variant="filled">
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={() => setIsOpen(false)}>
          {t('cancel')}
        </Button>
        <Button disabled={isLoading} variant="contained" onClick={onConfirmRemove}>
          {t('settings.summary.seat_remove.yes_remove_seat')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SeatsRemoveModal;

import React, { useRef, KeyboardEvent, useContext, useEffect } from 'react';
import { Col, Layout, Row } from 'antd';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

import { ProfileActions, HelpButton, Paragraph, Button, Icon, Tooltip } from 'components';
import PriceInput from 'components/priceInput';
import { Endpoints } from 'utils/endpoints';
import useUpdateVariables from 'hooks/useUpdateVariables';
import { UpdateDocumentPayloadByContentId } from 'services/documents/DocumentRepository';
import { DocumentStatus } from 'services/documents/entities';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import './styles.less';
import SaveStatusText from '../shared/components/SaveStatus/SaveStatus';
import { EditorToolbar } from './EditorToolbar';
import { updateVariableValue } from '../grid/reduxStore/allVariableSlice';
import { setSaveStatus } from '../grid/reduxStore/saveStatusSlice';
import { useAppDispatch, RootState } from '../grid/reduxStore/Store';
import { EditorContentContext } from 'providers/EditorContentContext';
import { VariableType, VariableName } from 'components/editor/SidePanel/Variables/interfaces';
import { useFieldValidator } from '../hooks/useFieldValidator';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { ShareDocument } from './ShareDocument';
import { VariableEntity } from '../../../services/entities/VariablesEntity';
import { Helmet } from 'react-helmet';

interface EditorMenuProps {
  documentId: string;
}

export const sanitizeTitle = (value: string) => {
  return DOMPurify.sanitize(value).substring(0, 99);
};

const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.currentTarget.blur();
  }
};

const findVariableByName = (variables: VariableEntity[] | undefined, name: string): string | number | null => {
  if (!variables) return null;
  const variable = variables.find((variable) => variable.name === name);
  return variable ? variable.value : null;
};

export const ConditionalSkeletonLoader: React.FC<{
  isAllElLoads: boolean;
  testId: string;
  width: number | string;
  height: number;
  children: React.ReactNode;
  [key: string]: any;
}> = ({ isAllElLoads, testId, width, height, children, ...otherProps }) => {
  return isAllElLoads ? (
    <Skeleton variant="rectangular" width={width} height={height} data-testid={testId} {...otherProps} />
  ) : (
    <>{children}</>
  );
};

export function EditorMenu({ documentId }: EditorMenuProps) {
  const allVariables = useSelector((state: RootState) => state.allVariables.data);
  const contentName = findVariableByName(allVariables?.proposal, VariableName.ProposalName);
  const contentPrice = Number(findVariableByName(allVariables?.proposal, VariableName.Total));
  const { contentId } = useContext(EditorContentContext);
  const previewURL = Endpoints.getDocumentPreviewPage(documentId);
  const handlePreviewRedirect = () => window.open(previewURL, '_blank');
  const documentTitleRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const { mutate } = useUpdateVariables();
  const { t } = useTranslation();
  const { data: documentData, isLoading, refetch: refetchDocument } = useGetDocumentByIdQuery(documentId);
  const dispatch = useAppDispatch();
  const isAllElLoading = allVariables === null && isLoading;
  const { formatter, parseValue, error, value: priceValue } = useFieldValidator(contentPrice);

  const handleOnChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateVariableValue({ type: VariableType.Proposal, variableName: VariableName.ProposalName, newValue: event.target.value }));
  };

  const updateDocumentProperties = (payload: Omit<UpdateDocumentPayloadByContentId, 'contentId'>) => {
    mutate({ contentId, ...payload });
  };

  const handleOnChangeTotal = (value: number | null) => value;

  const handleOnBlurTitle = () => {
    const newValue = contentName === '' || contentName == null ? t('editor.default_document_title') : sanitizeTitle(String(contentName));
    updateDocumentProperties({ title: newValue });
  };

  const handleOnBlurTotal = () => {
    if (!error) {
      updateDocumentProperties({ price: contentPrice });
    }
  };

  useEffect(() => {
    if (!error) {
      dispatch(
        updateVariableValue({
          type: VariableType.Proposal,
          variableName: VariableName.Total,
          newValue: priceValue,
        })
      );
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVED }));
    } else {
      dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED }));
    }
  }, [error, priceValue, dispatch]);

  const documentTitleWithCompanyName = [contentName, documentData?.company?.name].filter(Boolean).join(' - ') || contentName;

  return (
    <Layout.Header className="editor-header">
      <Helmet>
        <title>{String(contentName)} | Editor</title>
      </Helmet>
      <Row justify="space-between" align="top" className="editor-menu-container">
        <Col className="editor-menu-container__column--left document-details">
          <ConditionalSkeletonLoader variant="rounded" isAllElLoads={isAllElLoading} testId="skeleton-title" width="50vw" height={24}>
            <Tooltip title={String(contentName)} placement="bottom">
              <input
                ref={documentTitleRef}
                data-testid="document-title"
                onKeyDown={blurOnEnter}
                className="document-title"
                maxLength={100}
                placeholder={t('editor.default_document_title')}
                onChange={handleOnChangeTitle}
                onBlur={handleOnBlurTitle}
                value={!contentName ? undefined : String(contentName)}
              />
            </Tooltip>
          </ConditionalSkeletonLoader>
          <div className="document-status">
            <ConditionalSkeletonLoader isAllElLoads={isAllElLoading} testId="skeleton-status" variant="text" width={43} height={30}>
              <Paragraph size="sm">{(documentData?.status || DocumentStatus.Draft).replace('_', ' ')}</Paragraph>$&nbsp;
            </ConditionalSkeletonLoader>
            <ConditionalSkeletonLoader variant="text" isAllElLoads={isAllElLoading} testId="skeleton-price" width={120} height={30}>
              <Tooltip className={'tooltip__error'} placement="bottom" title={error ? error : ''}>
                <>
                  <PriceInput
                    value={contentPrice}
                    errorClass={`${error ? 'price-input__error' : ''}`}
                    onChange={handleOnChangeTotal}
                    onBlur={handleOnBlurTotal}
                    formatter={formatter}
                    parseValue={parseValue}
                  />
                </>
              </Tooltip>
            </ConditionalSkeletonLoader>
            <ConditionalSkeletonLoader variant="text" isAllElLoads={isAllElLoading} testId="skeleton-save-status" width={120} height={30}>
              <Paragraph size="sm" className="timestamp-ml-xs">
                <SaveStatusText />
              </Paragraph>
            </ConditionalSkeletonLoader>
          </div>
        </Col>
        <Col className="editor-menu-container__column--right">
          <ConditionalSkeletonLoader variant="rectangular" isAllElLoads={isAllElLoading} testId="skeleton-buttons" width={300} height={40}>
            <div className="buttons-container">
              <Button
                icon={<Icon name="IcoPreview" title={t('preview')} />}
                variant="neutral"
                type="default"
                className="preview-button"
                onClick={handlePreviewRedirect}
              >
                {t('preview')}
              </Button>
              <ShareDocument
                documentId={documentId}
                documentTitleWithCompanyName={String(documentTitleWithCompanyName)}
                refetchDocument={refetchDocument}
              />
            </div>
          </ConditionalSkeletonLoader>
          <HelpButton />
          <ProfileActions />
        </Col>
      </Row>
      <EditorToolbar documentId={documentId} />
    </Layout.Header>
  );
}

import { PyMuiIconButton, PyMuiMenuItem, PyMuiMenu } from '../components/generalComponents';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import { TOOLBAR_COLORS } from '../helpers/toolbarStyleSettings';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { useState, MouseEvent } from 'react';
import { useActiveEditor } from '../ActiveEditorContext';
import { Editor } from '@tiptap/core';

const AlignmentIcon = ({ ...props }) => {
  return (
    <PyMuiIconButton {...props} outlinedColorWhenActive={TOOLBAR_COLORS.primary}>
      <FormatAlignLeftOutlinedIcon />
    </PyMuiIconButton>
  );
};

const AlignLeftIcon = ({ ...props }) => {
  return (
    <PyMuiIconButton {...props}>
      <FormatAlignLeftIcon />
    </PyMuiIconButton>
  );
};

const AlignCenterIcon = ({ ...props }) => {
  return (
    <PyMuiIconButton {...props}>
      <FormatAlignCenterIcon />
    </PyMuiIconButton>
  );
};

const AlignRightIcon = ({ ...props }) => {
  return (
    <PyMuiIconButton {...props}>
      <FormatAlignRightIcon />
    </PyMuiIconButton>
  );
};

const AlignJustifyIcon = ({ ...props }) => {
  return (
    <PyMuiIconButton {...props}>
      <FormatAlignJustifyIcon />
    </PyMuiIconButton>
  );
};

const isTextAlignActive = (editor: Editor) => {
  const alignments = ['left', 'center', 'right', 'justify'];
  const activeAlignments = alignments.filter((alignment) => editor.isActive('textAlign', { alignment }));
  return activeAlignments.length === 1;
};

const TextAlignment = () => {
  const { activeEditor } = useActiveEditor();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const alignmentOptions = [
    {
      testId: 'align-left',
      alignment: 'left',
      Icon: AlignLeftIcon,
      onClick: () => activeEditor?.chain().focus().setTextAlign('left').run(),
      isActive: activeEditor ? isTextAlignActive(activeEditor) : false,
    },
    {
      testId: 'align-center',
      alignment: 'center',
      Icon: AlignCenterIcon,
      onClick: () => activeEditor?.chain().focus().setTextAlign('center').run(),
      isActive: activeEditor ? isTextAlignActive(activeEditor) : false,
    },
    {
      testId: 'align-right',
      alignment: 'right',
      Icon: AlignRightIcon,
      onClick: () => activeEditor?.chain().focus().setTextAlign('right').run(),
      isActive: activeEditor ? isTextAlignActive(activeEditor) : false,
    },
    {
      testId: 'align-justify',
      alignment: 'justify',
      Icon: AlignJustifyIcon,
      onClick: () => activeEditor?.chain().focus().setTextAlign('justify').run(),
      isActive: activeEditor ? isTextAlignActive(activeEditor) : false,
    },
  ];

  return (
    <>
      <AlignmentIcon onClick={handleOpenMenu} outlinedColorWhenActive={TOOLBAR_COLORS.primary} />

      <PyMuiMenu
        testId="alignment-menu"
        id="select-alignment-menu"
        menuListProps="select-alignment-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleCloseMenu}
        direction="row"
      >
        {alignmentOptions.map(({ testId, Icon, onClick, isActive }) => (
          <PyMuiMenuItem key={testId} testId={testId} menuItem={<Icon onClick={onClick} isActive={isActive} />} />
        ))}
      </PyMuiMenu>
    </>
  );
};

export default TextAlignment;

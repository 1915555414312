import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FlexDataLinkDataGrid from './FlexDataLinkDataGrid';
import { ObjectType, TabPanelProps, DataSource } from '../types';

function FlexDataLinkContainerTabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`flex-data-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const MemoizedTabPanel = React.memo(FlexDataLinkContainerTabPanel);

interface ObjectTypeTabsProps {
  parentDataSource: DataSource;
  objectTypes: ObjectType[];
}

const ObjectTypeTabs: React.FC<ObjectTypeTabsProps> = ({ parentDataSource, objectTypes }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Tabs value={activeTabIndex} onChange={handleChange}>
          {objectTypes.map((objectTypeObject) => (
            <Tab key={objectTypeObject.objectTypeLabel} label={objectTypeObject.objectTypeLabel} />
          ))}
        </Tabs>
      </Box>
      {objectTypes.map((objectTypeObject, index) => (
        <MemoizedTabPanel key={objectTypeObject.objectTypeLabel} value={activeTabIndex} index={index}>
          <FlexDataLinkDataGrid parentDataSource={parentDataSource} parentObjectType={objectTypeObject.objectType} />
        </MemoizedTabPanel>
      ))}
    </Box>
  );
};

export default ObjectTypeTabs;

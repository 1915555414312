import { allTiers } from 'services/api/plan/utils';
import { PlanTier, nonLegacyTiers } from 'services/api/plan/interfaces';
import { PlanTierButtonConfigProps } from '../plan/interface';

export const isLegacyPlan = (planTier: PlanTier | string): boolean => {
  return nonLegacyTiers.indexOf(planTier) === -1;
};

export const getPlanButtonLabel = (planName: PlanTier, curTier: PlanTier, options?: PlanTierButtonConfigProps): string => {
  const curPlanPosition = allTiers.indexOf(curTier);
  const buttonPlanPosition = allTiers.indexOf(planName);

  if (curTier === '') {
    return 'settings.plan.button_select_plan_label';
  }

  if (options?.isTrialExpired) {
    return 'settings.plan.button_upgrade_plan_label';
  }

  if (curPlanPosition === buttonPlanPosition) {
    return options?.current || 'settings.plan.button_current_plan_label';
  }

  return curPlanPosition > buttonPlanPosition
    ? options?.downgrade || 'settings.plan.button_downgrade_plan_label'
    : options?.upgrade || 'settings.plan.button_upgrade_plan_label';
};

export const formatAsPrice = (price: number, hideDecimal = false) => {
  const defaultDigits = {
    minimumFractionDigits: !hideDecimal || price % 1 !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
  };

  return price.toLocaleString('en-US', { style: 'currency', currency: 'USD', ...defaultDigits });
};

export const FREE_COLLAB_SEATS_PER_PLAN = {
  basic: 2,
  team: 3,
  business: 5,
  default: 0,
} as const;

const cards: { name: string; lengths: number[]; prefixes: number[]; checkdigit: boolean }[] = [
  {
    name: 'Visa',
    lengths: [13, 16],
    prefixes: [4],
    checkdigit: true,
  },
  {
    name: 'MasterCard',
    lengths: [16],
    prefixes: [5],
    checkdigit: true,
  },
  {
    name: 'American Express',
    lengths: [15],
    prefixes: [3],
    checkdigit: true,
  },
];

interface CardValidationResult {
  state: boolean;
  message: string;
}

export const validateCreditCard = (cardNumber: string): CardValidationResult => {
  if (cardNumber.length == 0) {
    return {
      state: false,
      message: 'No card number provided',
    };
  }

  if (cardNumber.charAt(0) === '*') {
    return { state: true, message: 'Not updating credit card number' };
  }

  const cardType = cards.find((c) => c.prefixes.indexOf(Number(cardNumber.charAt(0))) > -1);
  if (!cardType) {
    return {
      state: false,
      message: 'Unknown card type',
    };
  }

  cardNumber = cardNumber.replace(/\s/g, '');
  const cardNo = cardNumber;
  const cardexp = /^[0-9]{13,19}$/;
  if (!cardexp.exec(cardNo)) {
    return {
      state: false,
      message: 'Credit card number is in invalid format',
    };
  }

  let lengthValid = false;
  for (let j = 0; j < cardType.lengths.length; j++) {
    if (cardNo.length == cardType.lengths[j]) {
      lengthValid = true;
      break;
    }
  }

  if (!lengthValid) {
    return {
      state: false,
      message: 'Credit card number has an inappropriate number of digits',
    };
  }

  return {
    state: true,
    message: 'Card valid',
  };
};

export const parseUnformattedApiErrors = (errorString: string): { field: string | null; message: string }[] => {
  const newApiErrors: { field: string | null; message: string }[] = [];
  errorString.split(/\r?\n/).map((err) => {
    let parsedErr = err.replaceAll(/(<([^>]+)>)/gi, '');
    parsedErr = parsedErr.replaceAll(/\r?\n/gi, '').trim();
    if (parsedErr !== '') {
      if (err.toLowerCase().indexOf('credit card number') > -1) {
        newApiErrors.push({ field: 'ccNumber', message: 'settings.billing.payment_method_form.invalid_credit_card' });
      } else if (err.toLowerCase().indexOf('verification value') > -1 || err.toLowerCase().indexOf('cvv') > -1) {
        newApiErrors.push({ field: 'securityCode', message: 'settings.billing.payment_method_form.invalid_security_code' });
      } else if (err.toLowerCase().indexOf('expiry') > -1) {
        newApiErrors.push({ field: 'expiry', message: 'settings.billing.payment_method_form.invalid_expiry_date' });
      } else if (err.toLowerCase().indexOf('zip') > -1 || err.toLowerCase().indexOf('postal') > -1) {
        newApiErrors.push({ field: 'zip', message: 'settings.billing.payment_method_form.invalid_zip' });
      } else {
        newApiErrors.push({ field: null, message: parsedErr });
      }
    }
  });

  return newApiErrors;
};

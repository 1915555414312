export const trimLeadingZeros = (value: string): string => {
  if (!value) return '';

  if (value.startsWith('.')) return trimLeadingZeros(`0${value}`);

  const [integerPart = '0', decimalPart = ''] = value.split('.');

  const trimmedInteger = integerPart.replace(/^0+(?=\d)/, '') || '0';

  if (!decimalPart || /^0+$/.test(decimalPart)) return trimmedInteger;

  const trimmedDecimal = decimalPart.replace(/0+$/, '');
  return trimmedDecimal ? `${trimmedInteger}.${trimmedDecimal}` : trimmedInteger;
};

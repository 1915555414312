import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import CardMedia from '@mui/material/CardMedia';
import { IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ProposifyIcon from '../../../images/icons/proposify-icon.svg';
import UpgradeImage from '../../../images/upgrade-image.png';

export interface UpgradeSuccessDialogProps {
  open: boolean;
  onClose: (value?: string) => void;
  planName: string;
}

const UpgradeSuccessModal = ({ onClose, open, planName }: UpgradeSuccessDialogProps) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="upgrade-success-title"
      open={open}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '25px',
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle
        id="upgrade-success-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px 12px 24px',
        }}
      >
        <Stack direction="row" gap="12px">
          <img src={ProposifyIcon} width="32px" height="32px" />
          <Typography variant="h6" color="text.primary">
            {t(`settings.plan.upgrade_success_modal.title_${planName}_plan`)}
          </Typography>
        </Stack>
        <IconButton sx={{ ml: 'auto' }} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 0, mb: 3, border: 'none' }}>
        <Stack direction="column" gap={3}>
          <Typography variant="body2">{t(`settings.plan.upgrade_success_modal.content_${planName}_plan`)}</Typography>
          <CardMedia
            sx={{ padding: 3, bgcolor: '#000', borderRadius: '16px' }}
            component="img"
            height="194"
            image={UpgradeImage}
            alt={t('settings.plan.upgrade_success_modal.image_alt') as string}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeSuccessModal;

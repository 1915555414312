import { Box } from '@mui/material';
import { EditorFroalaToolbar } from './EditorFroalaToolbar';
import { EditorUndoRedoToolbar } from './EditorUndoRedoToolbar';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { FeatureFlags } from '../../../utils/featureFlags';
import { useSelector } from 'react-redux';
import { RootState } from '../grid/reduxStore/Store';
import { TableSettingsTypes } from '../SidePanel/content/TableSettings/types';
import TipTapBarMenu from '../GridDndEditor/TiptapEditor/TipTapBarMenu';

export function EditorToolbar({ documentId }: { documentId?: string }) {
  const isTextStylingActive = useFeatureFlag([FeatureFlags.spTextStyling])[FeatureFlags.spTextStyling];
  const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const isActiveRowsPanel = activeTableSettingsPanel === TableSettingsTypes.TABLE_ROWS;
  const isTipTapMenuBarActive = isActiveRowsPanel && isTextStylingActive && documentId;

  return (
    <Box
      id="editor-toolbar-container"
      className={`editor-toolbar-container ${isTipTapMenuBarActive ? 'tiptap-menu-active' : ''}`}
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'normal',
        width: '100%',
        height: '50px',
        borderTop: '1px solid #f0f0f0',
        borderRadius: '5px',
        borderBottom: '1px solid #f0f0f0',
      }}
    >
      <EditorUndoRedoToolbar />
      {isTipTapMenuBarActive && <TipTapBarMenu documentId={documentId} />}
      <EditorFroalaToolbar />
    </Box>
  );
}

import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { DataSource } from '../types';

interface DataSourceSelectProps {
  dataSources: DataSource[];
  onChange: (event: SelectChangeEvent) => void;
  selectedDataSource: DataSource;
}

const DataSourceSelect: React.FC<DataSourceSelectProps> = ({ dataSources, onChange, selectedDataSource }) => {
  const isSingleDataSource = dataSources.length === 1;

  if (!selectedDataSource) {
    return <Typography sx={{ minWidth: 200, padding: 1 }}>Loading...</Typography>;
  }

  if (isSingleDataSource) {
    return <Typography sx={{ minWidth: 200, padding: 1 }}>{dataSources[0]?.dataSourceName}</Typography>;
  }
  const selectedValue = `${selectedDataSource.dataSourceType}::${selectedDataSource.tenantId}`;

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel id="data-source-type-select-label">App</InputLabel>
      <Select
        labelId="data-source-type-select-label"
        id="data-source-type-select"
        value={selectedValue}
        label="Data Source"
        onChange={onChange}
      >
        {dataSources.map(({ dataSourceType, tenantId, dataSourceName }) => (
          <MenuItem key={`${dataSourceType}::${tenantId}`} value={`${dataSourceType}::${tenantId}`}>
            {dataSourceName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DataSourceSelect;

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewedTimelineChart from './ViewedTimelineChart';

export function DocumentViews() {
  const { t } = useTranslation();

  return (
    <Card elevation={0}>
      <CardHeader
        title={<Typography variant="h6">{t('pipeline.insights.analytics.document_views')}</Typography>}
        subheader={<Typography variant="caption">{t('pipeline.insights.analytics.timeline_last_update')}</Typography>}
        titleTypographyProps={{ variant: 'h6' }}
        sx={{ paddingBottom: 0 }}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography alignSelf="flex-end" variant="body2">
          {t('pipeline.insights.analytics.timeline_total_views')}
        </Typography>
        <ViewedTimelineChart />
      </CardContent>
    </Card>
  );
}

import { useSectionData } from '../../../Sections/useSectionContext';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from '../../Block/Table/useTableManipulation';
import { useCallback, useContext, useEffect } from 'react';
import { ActiveEditorContext } from '../ActiveEditorContext';
import { SelectionContext } from '../../SelectedBlockInfoProvider';
import { AnyExtension, EditorContent, EditorEvents, useEditor } from '@tiptap/react';
import { RowTypesIdentifier } from '../../../grid/reduxStore/table.types';
import { getSanitizedDataForTableFooter } from '../../../../../utils/getSanitizedDataForTableFooter';
import { setTipTapExtensions } from '../helpers/config';

export const TipTapRenderEditCell = ({ id, field, hasFocus }) => {
  const { sectionId } = useSectionData();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { getTableData } = useTableManipulation();
  const { activeEditor, setActiveEditor } = useContext(ActiveEditorContext);
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const blockId = selectedBlockIdByWrapper as string;
  const tableData = getTableData(blockId, sectionId);
  const cellValue = tableData?.rows.find((row) => row.id === id)?.[field];

  const updateTableRow = (row, html, data) => {
    if (row.id !== id) return row;
    if (row.rowType === RowTypesIdentifier.FOOTER) {
      return { ...getSanitizedDataForTableFooter(row, data.columns, html), rowType: RowTypesIdentifier.FOOTER };
    }
    return { ...row, [field]: html };
  };

  const updateEditorData = useCallback(
    async (editor) => {
      const html = editor.getHTML();

      if (tableData) {
        const updatedRows = tableData.rows.map((row) => updateTableRow(row, html, tableData));

        await blockContentChangedHandler(blockId, sectionId, { ...tableData, rows: updatedRows });
      }
    },
    [blockId, sectionId, tableData, blockContentChangedHandler, field]
  );

  const editor = useEditor({
    content: cellValue ?? '',
    extensions: [...setTipTapExtensions] as AnyExtension[],
    onUpdate: async ({ editor }: EditorEvents['update']) => updateEditorData(editor),
    onBlur: async ({ editor }: EditorEvents['blur']) => updateEditorData(editor),
  });

  useEffect(() => {
    if (hasFocus && editor && activeEditor !== editor) {
      setActiveEditor(editor);
    }
  }, [editor, activeEditor, setActiveEditor, hasFocus]);

  return editor ? <EditorContent editor={editor} /> : null;
};

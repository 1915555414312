import { CommandProps, mergeAttributes } from '@tiptap/core';
import TiptapOrderedList from '@tiptap/extension-ordered-list';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customOrderedList: {
      toggleOrderedList: () => ReturnType;
      toggleOrderedListType: (type?: string) => ReturnType;
    };
  }
}
export const OrderedList = TiptapOrderedList.extend({
  addAttributes() {
    return {
      type: {
        default: 'decimal',
        parseHTML(element) {
          return element.style.listStyleType || element.getAttribute('type');
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    const { start, type, ...attributesWithoutStart } = HTMLAttributes;
    const style = `list-style-type: ${type};`;
    return start === 1
      ? ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart, { style }), 0]
      : ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { style }), 0];
  },
  addCommands() {
    return {
      toggleOrderedList: () => {
        return ({ chain }) => {
          return chain()
            .toggleList(this.name, this.options.itemTypeName)
            .updateAttributes(this.name, {
              ...this.editor.getAttributes('paragraph'),
            })
            .run();
        };
      },
      toggleOrderedListType: (type?: string) => {
        return ({ chain, editor, commands }: CommandProps) => {
          // If no type is provided, toggle the list. Will apply default (decimal) type.
          if (!type) {
            return chain().focus().toggleOrderedList().run();
          }

          // If the list type is not set, set the list type.
          if (!editor.getAttributes(this.type).type) {
            return chain().focus().toggleOrderedList().updateAttributes(this.type, { type }).run();
          }

          // If the list type is not the same as the type provided, update the list type.
          if (editor.getAttributes(this.type).type !== type) {
            return commands.updateAttributes(this.type, { type });
          }

          // If the list type is the same as the type provided, toggle the list.
          return chain().focus().toggleOrderedList().run();
        };
      },
    };
  },
});

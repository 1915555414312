import { css } from '@emotion/react';

export const fontSizes: Record<number, number> = {
  1: 42,
  2: 32,
  3: 24,
  4: 18,
};

const headingStyles = css({
  fontFamily: 'Gellix',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.15px',
  marginBottom: 0,
});

export const defaultStyle = css({
  '.tiptap': {
    h1: {
      fontSize: fontSizes[1],
      fontWeight: 'bold',
      ...headingStyles,
    },
    h2: {
      fontSize: fontSizes[2],
      fontWeight: 'bold',
      ...headingStyles,
    },
    h3: {
      fontSize: fontSizes[3],
      ...headingStyles,
    },
    h4: {
      fontSize: fontSizes[4],
      ...headingStyles,
    },
    p: {
      fontSize: 14,
    },
  },
});

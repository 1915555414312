import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FlexDataLinkDataGridProps, FlexDataLink, Params } from '../types';
import FlexDataLinkAutocomplete from './FlexDataLinkAutoComplete';
import { FlexDataLinkRepository } from 'services/flex-data/repositories/FlexDataLinkRepository';
import { openNotification } from 'components';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useFilteredFlexDataLink } from '../useFilteredFlexDataLink';
import { Skeleton } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

const FlexDataLinkDataGrid: React.FC<FlexDataLinkDataGridProps> = ({ parentDataSource, parentObjectType }) => {
  const { documentId } = useParams<keyof Params>() as Params;
  const { isFlexDataLinkLoading, filteredFlexDataLinks, isFlexDataLinkError } = useFilteredFlexDataLink(
    documentId,
    parentDataSource.dataSourceType,
    parentDataSource.tenantId,
    parentObjectType
  );
  const [flexDataLinks, setFlexDataLinks] = useState<FlexDataLink[]>(filteredFlexDataLinks);
  const [selectedFlexDataLink, setSelectedFlexDataLink] = useState<FlexDataLink | null>(null);
  useEffect(() => {
    setFlexDataLinks(filteredFlexDataLinks);
  }, [filteredFlexDataLinks]);

  if (isFlexDataLinkLoading) {
    return (
      <>
        <Skeleton variant="rectangular" width="50%" height={30} data-testid="skeleton-loader" />
      </>
    );
  }

  if (isFlexDataLinkError) {
    return (
      <div style={{ padding: '20px', textAlign: 'center', color: 'red' }}>
        <h2>Error Loading Data</h2>
        <p>Please try again later.</p>
      </div>
    );
  }

  const handleOptionChange = async (event: React.SyntheticEvent, selectedOption: any) => {
    setSelectedFlexDataLink(selectedOption);
  };

  const handleAddFlexDataLink = async () => {
    if (!selectedFlexDataLink) return;
    try {
      const savedFlexDataLink: FlexDataLink = await FlexDataLinkRepository.upsertFlexDataLink(documentId, selectedFlexDataLink);
      savedFlexDataLink.objectLabel = selectedFlexDataLink.objectLabel;
      setFlexDataLinks((prevLinks) => {
        const existing = prevLinks.filter((link) => link.objectId === savedFlexDataLink.objectId);
        if (existing.length === 0) {
          return [...prevLinks, savedFlexDataLink];
        }
        return prevLinks;
      });

      setSelectedFlexDataLink(null);
    } catch (error) {
      openNotification({
        type: 'error',
        title: 'Unable to add this link',
        description: 'Something went wrong. Please try again later.',
        placement: 'top',
      });
    }
  };

  const handleDeleteFlexDataLink = async (flexDataLink: FlexDataLink) => {
    try {
      await FlexDataLinkRepository.deleteFlexDataLink(documentId, flexDataLink);

      setFlexDataLinks((prevLinks) => prevLinks.filter((link) => link.objectId !== flexDataLink.objectId));

      openNotification({
        type: 'success',
        title: 'Deleted Successfully',
        description: 'The Flex Data Link has been removed.',
        placement: 'top',
      });
    } catch (error) {
      openNotification({
        type: 'error',
        title: 'Delete Failed',
        description: 'Something went wrong. Please try again later.',
        placement: 'top',
      });
    }
  };

  const handleQualifierEdit = async (updatedRow, originalRow) => {
    if (originalRow.qualifier !== updatedRow.qualifier) {
      FlexDataLinkRepository.upsertFlexDataLink(documentId, updatedRow);
    }
    return updatedRow;
  };

  const linkObjectDataGridColumns: GridColDef[] = [
    {
      field: 'objectLabel',
      headerName: 'Name',
      width: 250,
      editable: false,
    },
    {
      field: 'qualifier',
      headerName: 'Link Type',
      width: 250,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Delete',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton aria-label="delete" onClick={() => handleDeleteFlexDataLink(params.row)} size="small">
          <DeleteOutline />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <FlexDataLinkAutocomplete parentDataSource={parentDataSource} parentObjectType={parentObjectType} onChange={handleOptionChange} />
      <Button
        variant="contained"
        sx={{
          fontFamily: 'inherit',
          borderRadius: '100px',
          textTransform: 'none',
          padding: '6px 16px',
          marginBottom: 2,
          fontSize: '14px',
          fontWeight: 600,
          backgroundColor: 'rgba(0, 40, 58, 1)',
          color: '#fff',
          '&:hover': { backgroundColor: 'rgba(0, 40, 58, 1)' },
        }}
        onClick={handleAddFlexDataLink}
        disabled={!selectedFlexDataLink}
      >
        Add Flex Data Link
      </Button>
      <DataGrid
        columns={linkObjectDataGridColumns}
        rows={flexDataLinks}
        hideFooter={true}
        getRowId={(row) => row.objectId}
        processRowUpdate={handleQualifierEdit}
      />
    </>
  );
};

export default FlexDataLinkDataGrid;

import { Endpoints } from 'utils/endpoints';
import { apiClient } from 'services/api';
import { FlexDataLink, FlexDataLinkResponse } from 'pages/flex-data/flex-data-links/types';

class FlexDataLinkRepositoryClass {
  public async getFlexDataLinks(documentId: string): Promise<FlexDataLinkResponse> {
    return await apiClient.get(Endpoints.getFlexDataLinks(documentId));
  }

  public async searchFlexDataLinks(
    dataSourceType: string,
    tenantId: string,
    objectType: string,
    keywords: string
  ): Promise<FlexDataLinkResponse> {
    const params = {
      dataSourceType: dataSourceType,
      tenantId: tenantId,
      objectType: objectType,
      keywords: keywords,
    };
    return await apiClient.get(Endpoints.searchFlexDataLinks, { params });
  }
  public async upsertFlexDataLink(documentId: string, flexDataLink: FlexDataLink): Promise<FlexDataLink> {
    return await apiClient.post(Endpoints.upsertFlexDataLink(documentId), flexDataLink);
  }
  public async deleteFlexDataLink(documentId: string, flexDataLink: FlexDataLink) {
    return await apiClient.post(Endpoints.unlinkFlexDataLink(documentId), flexDataLink);
  }
}

export const FlexDataLinkRepository = new FlexDataLinkRepositoryClass();

const withRootSlash = (url: string) => {
  const firstChar = url.charAt(0);

  if (firstChar !== '/') {
    return '/' + url;
  }

  return url;
};

export const constructCinderUrl = (path: string) => `${globalThis.env.VITE_LEGACY_APP_URL}${withRootSlash(path)}`;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Editor } from '@tiptap/react';
import { useEffect, useMemo, useState, useCallback } from 'react';

import Icon from '../components/Icon';
import IcoArrowDropDownRounded from 'components/icon/icons/IcoArrowDropDownRounded';

import PyMuiButton from '../components/generalComponents/PyMuiButton';
import PyMuiMenu from '../components/generalComponents/PyMuiMenu';
import PyMuiMenuItem from '../components/generalComponents/PyMuiMenuItem';
import { useInstalledFonts } from '../../../../../hooks/useInstalledFonts';

const DEFAULT_FONT = 'Arial';

const defaultFontFamilies = [
  { name: DEFAULT_FONT },
  { name: 'Georgia' },
  { name: 'Helvetica Neue' },
  { name: 'Plus Jakarta Sans' },
  { name: 'Roboto' },
  { name: 'Times New Roman' },
  { name: 'Work Sans' },
];

const isActive = (editor: Editor | null, fontFamily: string) => editor?.isActive('fontFamily', { fontFamily }) ?? false;

const styles = {
  fontFamilyContainer: css({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
  }),
  fontLabel: css({
    minWidth: 120,
    textAlign: 'left',
    padding: '6px 2px 6px 6px',
    cursor: 'pointer',
  }),
};

type Props = {
  editor: Editor;
  documentId?: string;
};

const FontFamily = ({ documentId, editor }: Props) => {
  const { result: installedFonts } = useInstalledFonts(documentId ?? '');

  const allAvailableFonts = useMemo(() => [...defaultFontFamilies, ...installedFonts], [installedFonts]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState<string>(DEFAULT_FONT);

  const selectedLabel = useMemo(
    () => allAvailableFonts.find((option) => option.name === selected)?.name ?? DEFAULT_FONT,
    [selected, allAvailableFonts]
  );

  const handleOpenMenu = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleSelectFontFamily = useCallback(
    (fontFamily: string) => {
      editor?.chain().focus()[fontFamily ? 'setFontFamily' : 'unsetFontFamily'](fontFamily).run();

      setSelected(fontFamily || DEFAULT_FONT);
      handleClose();
    },
    [editor, handleClose]
  );

  useEffect(() => {
    if (!editor?.isEditable) return;

    const font = editor.getAttributes('textStyle')?.fontFamily ?? DEFAULT_FONT;
    setSelected(font.replace(/"/g, ''));
  }, [editor?.state]);

  return (
    <span css={styles.fontFamilyContainer}>
      <PyMuiButton
        width={152}
        testId="font-family-button"
        isMenuOpen={Boolean(anchorEl)}
        onClickAction={handleOpenMenu}
        isActive={isActive(editor, selected)}
      >
        <span css={styles.fontLabel} style={{ fontFamily: selectedLabel }}>
          {selectedLabel}
        </span>
        <Icon>
          <IcoArrowDropDownRounded />
        </Icon>
      </PyMuiButton>

      <PyMuiMenu
        testId="font-family-menu"
        id="select-font-family-menu"
        menuListProps="select-font-family-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleClose}
      >
        {allAvailableFonts.map(({ name: fontFamily }) => (
          <PyMuiMenuItem
            key={fontFamily}
            testId={`font-family-menu-item-${fontFamily.replace(/\s+/g, '-').toLowerCase()}`}
            onClick={() => handleSelectFontFamily(fontFamily)}
            isActive={selected === fontFamily}
            menuItem={fontFamily}
            isFontFamily
            customStyle={{ minWidth: 220 }}
          />
        ))}
      </PyMuiMenu>
    </span>
  );
};

export default FontFamily;

import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useSearchFlexDataLinks } from '../useSearchFlexDataLinks';
import { DataSource } from '../types';

interface FlexDataLinkAutocompleteProps {
  parentDataSource: DataSource;
  parentObjectType: string;
  onChange: (event: React.SyntheticEvent, value: any) => void;
}

const FlexDataLinkAutocomplete: React.FC<FlexDataLinkAutocompleteProps> = ({ parentDataSource, parentObjectType, onChange }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');

  const dataSourceType = parentDataSource.dataSourceType;
  const tenantId = parentDataSource.tenantId;

  const { flexDataLinkOptions, isFlexDataLinkOptionsLoading, isFlexDataLinkOptionsError } = useSearchFlexDataLinks(
    dataSourceType,
    tenantId,
    parentObjectType,
    debouncedInputValue
  );

  const handleInputChange = (_: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleOpen = () => {
    setOpen(true);
    setDebouncedInputValue(' ');
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  if (isFlexDataLinkOptionsError) {
    return <div>Error loading options. Please try again.</div>;
  }

  let flexDataLinkOptionsForAutoComplete: typeof flexDataLinkOptions = [];

  if (flexDataLinkOptions?.length > 0) {
    flexDataLinkOptionsForAutoComplete = flexDataLinkOptions.map((flexDataLinkOption) => ({
      ...flexDataLinkOption,
      label: flexDataLinkOption.objectLabel,
    }));
  }

  return (
    <Autocomplete
      fullWidth
      freeSolo
      options={flexDataLinkOptionsForAutoComplete}
      loading={isFlexDataLinkOptionsLoading}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={onChange}
      onInputChange={handleInputChange}
      sx={{
        fontFamily: 'inherit',
        borderRadius: '100px',
        textTransform: 'none',
        padding: '6px 0px',
        marginBottom: 2,
        fontSize: '14px',
        fontWeight: 600,
      }}
      renderInput={(params) => <TextField {...params} label="Search" variant="outlined" fullWidth />}
    />
  );
};

export default FlexDataLinkAutocomplete;

import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { DataGridProProps, GridRowHeightReturnValue } from '@mui/x-data-grid-pro';
import './fonts.css';
import { ColumnTypeIdentifier } from 'components/editor/grid/reduxStore/table.types';
import { getRowClassName } from './dataGridUtils';

export const COLORS = {
  HoverNFocus: '#49D8F1',
  white: '#ffffff',
  appFog700: '#565858',
  appFog800: '#353636',
  footerHeader: '#00658C',
  colorHeaderRow: '#41484D',
  subtotalTaxDiscount: '#001E2D',
  subtotalTaxDiscountBg: '#EAF5FF',
  borderColor: '#13BCD5',
  cellColor: '#00283ADD',
  borderBottomColor: 'rgba(224, 229, 231, 1)',
  disabledIcon: '#191C1E14',
  iconBgColor: 'rgba(0, 0, 0, 0.04)',
  greyedBackgroundColor: '#DDE3EA',
};

export const SIZES = {
  defaultRowHeight: '52px',
  defaultRowFooterHeight: '40px',
  defaultFontWeight: 400,
  columnHeaderHeight: 33,
};

const STYLES = {
  blueBorderStyle: `1px solid ${COLORS.HoverNFocus}`,
  focusedBoxShadow: '0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)',
};

export const SPACING = {
  sm: '10px',
};

const getRowHeight = (): GridRowHeightReturnValue => {
  return 'auto';
};

export const tableColumnTypes = {
  TEXT: ColumnTypeIdentifier.TEXT,
  MULTIPLIER: ColumnTypeIdentifier.MULTIPLIER,
  PRICE: ColumnTypeIdentifier.PRICE,
  SUBTOTAL: ColumnTypeIdentifier.SUBTOTAL,
};

export const MuiDataGrid: {
  defaultProps?: Partial<DataGridProProps<any>>;
  styleOverrides?: Partial<OverridesStyleRules>;
} = {
  defaultProps: {
    columnHeaderHeight: SIZES.columnHeaderHeight,
    showColumnVerticalBorder: true,
    hideFooter: false,
    hideFooterRowCount: true,
    hideFooterPagination: true,
    hideFooterSelectedRowCount: true,
    disableColumnMenu: true,
    disableColumnSorting: true,
    getRowClassName,
    getRowHeight,
    autoHeight: true,
    columnVisibilityModel: { id: false },
  },
  styleOverrides: {
    root: {
      fontSize: '1rem',
      fontFamily: 'roboto, sans-serif',
      fontWeight: SIZES.defaultFontWeight,
      '.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--resizing': {
        color: COLORS.borderColor,
      },
      'div[class*="MuiDataGrid-footerContainer"]': {
        display: 'none',
      },
      ':not(&.py-contentful-state) .MuiDataGrid-container--top': {
        position: 'absolute',
        zIndex: 0,
        visibility: 'hidden',
      },
      '&.py-selected-state': {
        zIndex: -1,
      },
      '&.py-contentful-state': {
        zIndex: 'auto',
        '.py-selected-column-cell': {
          borderLeft: STYLES.blueBorderStyle,
          borderRight: STYLES.blueBorderStyle,
        },
        '.Mui-selected': { boxShadow: STYLES.focusedBoxShadow },
      },
      '.MuiDataGrid-filler, .MuiDataGrid-scrollbar': {
        display: 'none',
      },
      'input[type="number"]': {
        textAlign: 'right',
      },
      '.py-custom-input': {
        border: 'none',
        color: COLORS.cellColor,
        paddingLeft: SPACING.sm,
        paddingRight: SPACING.sm,
        fontSize: '1rem',
        backgroundColor: 'transparent',
        '&:focus-visible': {
          outline: 'none',
          border: 'none',
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          margin: 5,
        },
      },
      '.py-custom-cell-wrapper': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        input: {
          width: '100%',
          paddingLeft: SPACING.sm,
          paddingRight: SPACING.sm,
        },
      },
      '.py-number-input-no-arrow': {
        '& input[type=number]': {
          MozAppearance: 'text field',
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    columnHeaderRow: {
      '--DataGrid-containerBackground': COLORS.greyedBackgroundColor,
      color: COLORS.colorHeaderRow,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 24,
      borderBottom: '1px inner solid rgba(0, 0, 0, 0.12)',
      '.MuiDataGrid-withBorderColor': {
        borderRight: `1px solid ${COLORS.white}`,
      },
    },
    columnHeader: {
      '&:hover, &:focus, &:focus-within': {
        backgroundColor: '#A3EEFF',
        outline: 'none',
      },
    },
    row: {
      minHeight: `${SIZES.defaultRowHeight} !important`,
      '&:hover': {
        backgroundColor: 'transparent',
        '--rowBorderColor': COLORS.HoverNFocus,
        '.MuiDataGrid-cell': {
          borderBottom: '1px solid var(--rowBorderColor)',
        },
      },
      '&.py-total-row:last-child, &.py-header-row, &.py-footer-row': {
        backgroundColor: COLORS.footerHeader,
        fontWeight: 600,
        lineHeight: 'inherit',
        color: COLORS.white,
      },
      '&.py-total-row': {
        backgroundColor: COLORS.subtotalTaxDiscountBg,
        fontWeight: 600,
        color: COLORS.subtotalTaxDiscount,
        '&.py-hide-row, &.py-hide-row:hover': {
          backgroundColor: COLORS.greyedBackgroundColor,
        },
      },
      '&.py-footer-row .MuiDataGrid-cell': {
        minHeight: `${SIZES.defaultRowFooterHeight}`,
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&:hover': { backgroundColor: 'transparent' },
        '&.py-total-row:last-child, &.py-header-row, &.py-footer-row': {
          backgroundColor: COLORS.footerHeader,

          '&:hover': { backgroundColor: COLORS.footerHeader },
        },
      },
    },
    cell: {
      borderBottom: '1px solid transparent',
      display: 'flex',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      alignItems: 'start',
      '& > div, .tiptap.ProseMirror': {
        width: '100%',
        '&.ProseMirror-focused': {
          outlineColor: 'transparent',
        },
      },
      '& .tiptap.ProseMirror p': {
        marginBottom: 0,
        paddingTop: 12,
        paddingBottom: 12,
      },

      '&[data-field="optionalColumn"]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '.Mui-checked.py-row-optional': {
        color: '#2196F3',
      },
      'input[type="number"]': {
        textAlign: 'right',
      },
      '&.MuiDataGrid-rowReorderCellContainer': {
        alignItems: 'unset',
        wordBreak: 'unset',
      },
      '&:focus, &.MuiDataGrid-cell--editing:focus-within, &:focus-within': {
        outline: STYLES.blueBorderStyle,
      },
      '&.MuiDataGrid-cell': {
        '&--textRight': {
          '.editable-cell-wrapper': {
            justifyContent: 'flex-end',
          },
        },
        '&--editing, &--editing:focus-within': {
          color: COLORS.colorHeaderRow,
          paddingLeft: 10,
          paddingRight: 10,
        },
        '.py-mui-tiptap-input': {
          borderRadius: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          minHeight: '16px',
          '& p': {
            paddingTop: '12px',
            paddingBottom: '12px',
            '&.is-editor-empty:first-child::before': {
              content: 'attr(data-placeholder)',
              float: 'left',
              height: 0,
              pointerEvents: 'none',
              color: '#bdbdbd',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '157.143%',
            },
          },
        },
        '.py-mui-tiptap-input-preview': {
          border: 'none',
          padding: 0,
          minHeight: 'initial',
          alignSelf: 'stretch',
        },
      },
      '&.py-total-cell': {
        justifyContent: 'flex-end',
        textAlign: 'right',
      },
      '.py-custom-input': {
        border: 'none',
        color: COLORS.cellColor,
        fontSize: '1rem',
        '&:focus-visible': {
          outline: 'none',
          border: 'none',
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          margin: 5,
        },
      },
      '.py-number-input-no-arrow': {
        border: 'none',
        color: COLORS.cellColor,
        fontSize: '1rem',

        '&:focus-visible': {
          outline: 'none',
          border: 'none',
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          display: 'none',
        },
        '&[type="number"]': {
          MozAppearance: 'textfield',
        },
      },

      '&.py-hide-cell': {
        backgroundColor: COLORS.greyedBackgroundColor,
        input: {
          backgroundColor: COLORS.greyedBackgroundColor,
        },
      },

      '.py-value-formatter': {
        marginLeft: `-${SPACING.sm}`,
      },
      '> span': {
        paddingTop: '12px',
        paddingBottom: '12px',
        '.MuiCheckbox-root': {
          marginTop: '-12px',
          marginBottom: '-12px',
        },
      },
    },
    rowReorderCell: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    rowReorderCellPlaceholder: {
      fontWeight: SIZES.defaultFontWeight,
    },
  },
};

import { ActivitySummary } from './ActivitySummary';
import { AnalyticsProvider } from './AnalyticsProvider';
import { DocumentViews } from './DocumentViews';
import { ViewedSections } from './ViewedSections';
import { ViewerFilter } from './ViewerFilter';

export function Analytics() {
  return (
    <AnalyticsProvider>
      <ViewerFilter />
      <DocumentViews />
      <ActivitySummary />
      <ViewedSections />
    </AnalyticsProvider>
  );
}

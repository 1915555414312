/* eslint-disable @typescript-eslint/no-empty-function */
// TODO: replace when all modals are completed

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SeatsAddModal from './SeatsAddModal';
import { BillingCard, BillingCardContent } from '../utils/styled';
import BillingCardHeader from '../utils/BillingCardHeader';
import { SummaryBlock } from '../utils/SummaryBlock';
import MenuIcon from '@mui/icons-material/MoreHoriz';
import { OccupiedSeat, SeatsEntity, SeatsPagination, EmptySeat } from 'services/api/seats/interfaces';
import { useSeatsApiData } from 'services/api/seats/hooks/useSeatsApiData';
import Menu, { MenuProps } from '@mui/material/Menu';
import SeatsInviteModal from './SeatsInviteModal';
import SeatsRemoveModal from './SeatsRemoveModal';
import SeatsUnassignModal from './SeatsUnassignModal';
import ResendInviteModal from './ResendInviteModal';
import { Person } from '@mui/icons-material';

interface ActiveMenuItem {
  anchorElement: HTMLElement | null;
  seat: OccupiedSeat | EmptySeat | null;
}

type SeatMenu = (SeatMenuItem | null)[];

interface SeatMenuItem {
  label: string;
  color?: 'error' | 'success';
  action: (seat: OccupiedSeat | EmptySeat | null | undefined) => void;
  disabled?: boolean;
  tooltip?: string | null | undefined;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={8}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(1),
  },
}));

const SeatsSummary = () => {
  const { t } = useTranslation();
  const [isSeatsOpen, setIsSeatsOpen] = useState<boolean>(false);
  const [isSeatsInviteOpen, setIsSeatsInviteOpen] = useState<boolean>(false);
  const [isRemoveSeatOpen, setIsRemoveSeatOpen] = useState<boolean>(false);
  const [isUnassignSeatOpen, setIsUnassignSeatOpen] = useState<boolean>(false);
  const [isResendInviteOpen, setIsResendInviteOpen] = useState<boolean>(false);
  const [activeMenuItem, setActiveMenuItem] = useState<ActiveMenuItem | null>(null);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [pagination, setPagination] = useState<SeatsPagination>({
    rowsPerPage: 5,
    cursor: '',
    showEmpty: false,
  });
  const [history, setHistory] = useState<SeatsEntity[]>([]);

  const { accountId, setNotifications, billingData } = useContext(PlanSummaryContext);

  const {
    data: seatsData,
    isLoading: isSeatsLoading,
    isRefetching: isSeatsRefetching,
    refetch,
  } = useSeatsApiData({ accountId: accountId });

  const addHistoryItem = useCallback(
    (historyItem: SeatsEntity) => {
      setHistory([...history, historyItem]);
    },
    [setHistory, history]
  );

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, seat: OccupiedSeat | EmptySeat | null) => {
      setActiveMenuItem({ anchorElement: event.currentTarget || null, seat: seat });
      setIsContextMenuOpen(true);
    },
    [setActiveMenuItem, setIsContextMenuOpen]
  );

  const handleMenuClose = useCallback(() => {
    setIsContextMenuOpen(false);
    setActiveMenuItem(null);
  }, [setIsContextMenuOpen, setActiveMenuItem]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (history[newPage]) {
        setPage(newPage);
        return;
      }
      setPage(newPage);
      setPagination({ ...pagination });
    },
    [page, setPage, history, pagination]
  );

  const handleRowChange = useCallback(
    (event) => {
      setPagination({ cursor: '', rowsPerPage: Number(event.target.value), showEmpty: pagination.showEmpty });
      setPage(0);
      setHistory([]);
    },
    [pagination, setPagination, setHistory]
  );

  const handleShowEmpty = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPagination({
        ...pagination,
        cursor: '',
        showEmpty: event.target.checked,
      });
    },
    [pagination, setPagination]
  );

  useEffect(() => {
    if (!seatsData) {
      return;
    }

    if (!history[page]) {
      addHistoryItem(seatsData);
    }
  }, [seatsData]);

  const menuOpen = useMemo<boolean>(() => !!activeMenuItem && isContextMenuOpen, [activeMenuItem, isContextMenuOpen]);

  const menuOptions = useMemo<SeatMenu>(() => {
    if (!activeMenuItem) {
      return [];
    }
    const removeDisabled = activeMenuItem?.seat?.type === 'collaborator' ? !seatsData?.collaborators.paid : !seatsData?.users?.paid;

    const removeMenuItem = {
      label: 'settings.summary.seats_summary.menu.remove_seat',
      action: () => {
        setIsRemoveSeatOpen(true);
        setIsContextMenuOpen(false);
      },
      disabled: removeDisabled,
      color: 'error',
      tooltip: removeDisabled ? 'settings.summary.seats_summary.menu.remove_tooltip' : null,
    };

    const inviteMenuItem = {
      label: 'settings.summary.seats_summary.menu.invite_someone',
      action: () => {
        setIsSeatsInviteOpen(true);
        setIsContextMenuOpen(false);
      },
      disabled: false,
    };

    const removeAssigneeMenuItem = {
      label: 'settings.summary.seats_summary.menu.remove_assignee',
      action: () => {
        setIsUnassignSeatOpen(true);
        setIsContextMenuOpen(false);
      },
      disabled: false,
    };

    const resendInviteMenuItem = {
      label: 'settings.summary.seats_summary.menu.resend_invite',
      action: () => {
        setIsResendInviteOpen(true);
        setIsContextMenuOpen(false);
      },
      disabled: false,
    };

    if (!activeMenuItem?.seat || !('status' in activeMenuItem.seat)) {
      return [inviteMenuItem, null, removeMenuItem];
    }

    if (activeMenuItem?.seat?.status === 'pending') {
      return [resendInviteMenuItem, removeAssigneeMenuItem, null, removeMenuItem];
    }

    return [removeAssigneeMenuItem, null, removeMenuItem];
  }, [activeMenuItem?.seat, seatsData?.users.paid, seatsData?.collaborators.paid]);

  const paginationTotal = useMemo<number>(() => {
    if (!seatsData) {
      return 0;
    }
    return seatsData.occupiedSeats.length + (pagination.showEmpty ? 2 : 0);
  }, [pagination.showEmpty, seatsData?.occupiedSeats]);

  const renderEmptyRow = (type: 'collaborator' | 'user') => {
    if (!seatsData) {
      return null;
    }
    const capitalizedType = `${type[0].toUpperCase()}${type.slice(1)}`;
    const remaining = type === 'collaborator' ? seatsData.collaborators.empty : seatsData.users.empty;
    const namePluralization = remaining > 1 ? `${capitalizedType} seats` : `${capitalizedType} seat`;
    const message =
      remaining === 0
        ? t('settings.summary.seats_summary.no_remaining_seats', { seat_type: capitalizedType })
        : t('settings.summary.seats_summary.empty_seats_remaining', { remaining: remaining, seat_type: namePluralization });
    return (
      <TableRow key={`seat-empty-${type}`} data-testid={`seats-seat-row-empty-${type}`}>
        <TableCell variant="body" sx={{ pl: 3, width: '40px' }} />
        <TableCell variant="body">{message}</TableCell>
        <TableCell variant="body" align="left" sx={{ textTransform: 'capitalize', width: '150px' }}>
          {t(`settings.summary.seats_summary.seat_types.${type}`)}
        </TableCell>
        <TableCell variant="body" align="center" sx={{ width: '117px' }}>
          <Chip
            label={t('settings.summary.seats_summary.states.empty')}
            variant="filled"
            color="default"
            sx={{ width: '85px', textTransform: 'capitalize' }}
            data-testid={`seats-seat-status-empty-${type}`}
          />
        </TableCell>
        <TableCell variant="body" align="center" sx={{ width: '56px', verticalAlign: 'center', py: '10px', pr: 3 }}>
          {remaining > 0 && (
            <Box sx={{ position: 'relative' }}>
              <IconButton
                id={`seats-seat-empty-${type}-menu-button`}
                aria-controls={menuOpen ? 'seats-seat-menu' : undefined}
                aria-haspopup="menu"
                aria-expanded={menuOpen ? 'true' : undefined}
                data-testid={`seats-seat-menu-empty-${type}`}
                onClick={(e) => handleMenuClick(e, { type })}
              >
                <MenuIcon color="action" sx={{ width: '24px', height: '24px' }} />
              </IconButton>
            </Box>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const onSeatRemoveSuccess = useCallback(() => {
    setNotifications([{ content: t('settings.summary.seat_remove.success'), severity: 'success' }]);
    refetch();
    setIsRemoveSeatOpen(false);
  }, [setNotifications, refetch, setIsRemoveSeatOpen]);

  const onSeatUnassignSuccess = useCallback(() => {
    setNotifications([{ content: t('settings.summary.seat_unassign.success'), severity: 'success' }]);
    refetch();
    setIsUnassignSeatOpen(false);
  }, [setNotifications, refetch, setIsUnassignSeatOpen]);

  const onResendInviteSuccess = useCallback(() => {
    setNotifications([{ content: t('settings.summary.resend_invite.success'), severity: 'success' }]);
    refetch();
    setIsResendInviteOpen(false);
  }, [setNotifications, refetch, setIsResendInviteOpen]);

  const onSeatsAddSuccess = useCallback(() => {
    setNotifications([{ content: t('settings.summary.seat_add.success'), severity: 'success' }]);
    refetch();
    setIsSeatsOpen(false);
  }, [setNotifications, refetch, setIsSeatsOpen]);

  const onSeatsInviteSuccess = useCallback(() => {
    setNotifications([{ content: t('settings.summary.seat_invite.success'), severity: 'success' }]);
    refetch();
    setIsSeatsInviteOpen(false);
  }, [setNotifications, refetch, setIsSeatsInviteOpen]);

  const rowsToShow = useMemo<OccupiedSeat[]>(() => {
    if (!seatsData?.occupiedSeats) {
      return [];
    }
    return seatsData.occupiedSeats.slice(page * pagination.rowsPerPage, (page + 1) * pagination.rowsPerPage);
  }, [seatsData?.occupiedSeats, page, pagination]);

  const headerActions = useMemo(() => {
    if (!billingData || !billingData.lastFour) {
      return [];
    }
    return [
      {
        label: t('settings.summary.seats_summary.add_seats'),
        onClick: () => setIsSeatsOpen(true),
        dataTestId: 'seat-invite-modal-open',
      },
    ];
  }, [billingData, setIsSeatsOpen]);

  return (
    <>
      <BillingCard data-testid="seat" sx={{ px: 0 }}>
        <BillingCardHeader title={t('settings.summary.seats_summary.title')} actions={headerActions} addSidePadding />
        <BillingCardContent sx={{ px: 0 }}>
          <Stack direction="column" gap={3} sx={{ width: '100%' }}>
            <Stack direction="row" gap={{ xs: 3, sm: 9 }} flexWrap={{ xs: 'wrap', sm: 'nowrap' }} width="100%" px={3} minWidth="100px">
              <SummaryBlock
                title={t('settings.summary.seats_summary.user')}
                value={t('settings.summary.seats_summary.of_used', {
                  used: seatsData?.users?.used || '0',
                  total: seatsData?.users?.total || '0',
                })}
                isLoading={!seatsData}
                tooltip={!seatsData?.users?.empty ? t('settings.summary.seats_summary.no_empty_seats') : null}
              />
              <SummaryBlock
                title={t('settings.summary.seats_summary.collaborator')}
                value={t('settings.summary.seats_summary.of_used', {
                  used: seatsData?.collaborators?.used || '0',
                  total: seatsData?.collaborators?.total || '0',
                })}
                isLoading={!seatsData}
                tooltip={!seatsData?.collaborators?.empty ? t('settings.summary.seats_summary.no_empty_collaborator_seats') : null}
              />
            </Stack>
            <Stack direction="column" gap={0}>
              <FormControlLabel
                control={<Checkbox checked={pagination.showEmpty} onChange={handleShowEmpty} name="show_empty" />}
                label={t('settings.summary.seats_summary.show_empty_seats')}
                sx={{ px: 3 }}
              />
              <TableContainer data-testid="seats-table" sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" sx={{ pl: 3 }} colSpan={2}>
                        {t('settings.summary.seats_summary.user')}
                      </TableCell>
                      <TableCell variant="head" sx={{ width: '150px' }}>
                        {t('settings.summary.seats_summary.seat_type')}
                      </TableCell>
                      <TableCell variant="head" align="center" sx={{ width: '117px' }}>
                        {t('settings.summary.seats_summary.status')}
                      </TableCell>
                      <TableCell variant="head" sx={{ width: '56px', pr: 3 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isSeatsRefetching || isSeatsLoading || !seatsData ? (
                      <>
                        {Array.from(Array(pagination.rowsPerPage), (_, idx) => (
                          <TableRow key={`row-empty-${idx}`} data-testid={'seats-seat-skeleton'}>
                            <TableCell variant="body" sx={{ pl: 3, width: '40px' }}>
                              <Skeleton width="40px" height="40px" variant="rounded" />
                            </TableCell>
                            <TableCell variant="body">
                              <Skeleton width="100%" height="40px" variant="rounded" />
                            </TableCell>
                            <TableCell variant="body" align="left" sx={{ width: '150px' }}>
                              <Skeleton width="100%" height="40px" variant="rounded" />
                            </TableCell>
                            <TableCell variant="body" align="center" sx={{ width: '127px' }}>
                              <Skeleton width="95px" height="40px" variant="rounded" />
                            </TableCell>
                            <TableCell variant="body" align="center" sx={{ verticalAlign: 'center', pr: 3, width: '54px' }}>
                              <Skeleton width="24px" height="24px" variant="rounded" />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        {pagination.showEmpty ? renderEmptyRow('collaborator') : null}
                        {pagination.showEmpty ? renderEmptyRow('user') : null}
                        {rowsToShow.map((seat, key) => (
                          <TableRow key={`seat-occupied-${key}`} data-testid={'seats-seat-row'}>
                            <TableCell variant="body" sx={{ pl: 3, width: '40px' }}>
                              <Avatar alt="avatar" variant="circular">
                                {seat.name ? `${seat.name.split(' ')[0][0]}${seat.name.split(' ')[1][0]}` : <Person />}
                              </Avatar>
                            </TableCell>
                            <TableCell variant="body">
                              <Stack direction="column">
                                {seat.name && (
                                  <Typography variant="body1" color="text.primary">
                                    {seat.name}
                                  </Typography>
                                )}
                                <Typography variant="body2" color="text.secondary">
                                  {seat.email}
                                  {seat.name === '' && seat.status === 'pending'
                                    ? ` (${t('settings.summary.seats_summary.states.pending').toLowerCase()})`
                                    : ''}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell variant="body" align="left" sx={{ textTransform: 'capitalize', width: '150px' }}>
                              {seat.type}
                            </TableCell>
                            <TableCell variant="body" align="center" sx={{ width: '117px' }}>
                              <Chip
                                label={seat.status}
                                variant="filled"
                                color={seat.status === 'active' ? 'success' : 'info'}
                                sx={{ width: '85px', textTransform: 'capitalize' }}
                                data-testid={`seats-seat-status-${key}`}
                              />
                            </TableCell>
                            {!seat.isOwner ? (
                              <TableCell variant="body" align="center" sx={{ width: '56px', verticalAlign: 'center', py: '10px', pr: 3 }}>
                                <Box sx={{ position: 'relative' }}>
                                  <IconButton
                                    id={`seats-seat-${seat.userId}-menu-button`}
                                    aria-controls={menuOpen ? 'seats-seat-menu' : undefined}
                                    aria-haspopup="menu"
                                    aria-expanded={menuOpen ? 'true' : undefined}
                                    data-testid={`seats-seat-menu-${key}`}
                                    onClick={(e) => handleMenuClick(e, seat)}
                                  >
                                    <MenuIcon color="action" sx={{ width: '24px', height: '24px' }} />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            ) : (
                              <TableCell variant="body"></TableCell>
                            )}
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={paginationTotal}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pagination.rowsPerPage}
                  rowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onRowsPerPageChange={handleRowChange}
                  disabled={isSeatsLoading}
                />
              </TableContainer>
            </Stack>
          </Stack>
          <StyledMenu
            id="seats-seat-menu"
            anchorEl={activeMenuItem?.anchorElement}
            open={menuOpen && !!activeMenuItem?.anchorElement}
            onClose={handleMenuClose}
          >
            {menuOptions.map((menuItem, key) =>
              menuItem ? (
                <MenuItem
                  key={`seats-menu-item-${key}`}
                  color={menuItem.color}
                  onClick={() => menuItem.action(activeMenuItem?.seat)}
                  sx={{
                    color: menuItem.color,
                  }}
                  disabled={menuItem.disabled}
                >
                  {menuItem.tooltip ? <Tooltip title={t(menuItem.tooltip)}>{t(menuItem.label)}</Tooltip> : <>{t(menuItem.label)}</>}
                </MenuItem>
              ) : (
                <Divider key={`seat-menu-item-divider-${key}`} />
              )
            )}
          </StyledMenu>
        </BillingCardContent>
      </BillingCard>

      <SeatsAddModal
        seats={{ usedCollaboratorSeats: seatsData?.collaborators.total, userSeats: 0 }}
        isOpen={isSeatsOpen}
        setIsOpen={setIsSeatsOpen}
        onSuccess={onSeatsAddSuccess}
      />
      <SeatsInviteModal
        isOpen={isSeatsInviteOpen}
        seatType={activeMenuItem?.seat?.type || 'collaborator'}
        setIsOpen={setIsSeatsInviteOpen}
        onSuccess={onSeatsInviteSuccess}
      />
      <SeatsRemoveModal
        isOpen={isRemoveSeatOpen}
        setIsOpen={setIsRemoveSeatOpen}
        onSuccess={onSeatRemoveSuccess}
        seatType={activeMenuItem?.seat?.type || 'collaborator'}
        userId={activeMenuItem?.seat?.userId}
        userName={activeMenuItem?.seat?.name}
        currentTotalSeats={activeMenuItem?.seat?.type === 'user' ? seatsData?.users?.total || 0 : seatsData?.collaborators?.total || 0}
      />
      <SeatsUnassignModal
        isOpen={isUnassignSeatOpen}
        setIsOpen={setIsUnassignSeatOpen}
        onSuccess={onSeatUnassignSuccess}
        seatType={activeMenuItem?.seat?.type || 'collaborator'}
        userId={activeMenuItem?.seat?.userId}
        userName={activeMenuItem?.seat?.name}
      />
      <ResendInviteModal
        isOpen={isResendInviteOpen}
        setIsOpen={setIsResendInviteOpen}
        onSuccess={onResendInviteSuccess}
        userId={activeMenuItem?.seat?.userId}
        userName={activeMenuItem?.seat?.name}
      />
    </>
  );
};

export default SeatsSummary;

import { useQuery } from 'react-query';
import { FlexDataLinkRepository } from 'services/flex-data/repositories/FlexDataLinkRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useFilteredFlexDataLink = (documentId: string, dataSourceType: string, tenantId: string, objectType: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: QueryKeys.getFlexDataLinks(documentId),
    queryFn: () => FlexDataLinkRepository.getFlexDataLinks(documentId),
    select: (data) => {
      return data.data.filter(
        (link) => link.dataSourceType === dataSourceType && link.tenantId === tenantId && link.objectType === objectType
      );
    },
  });

  return {
    isFlexDataLinkLoading: isLoading,
    filteredFlexDataLinks: data || [],
    isFlexDataLinkError: isError,
  };
};

import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import DataSourceSelect from './components/DataSourceSelect';
import { SelectChangeEvent } from '@mui/material';
import ObjectTypeTabs from './components/ObjectTypeTabs';
import { useTopLevelObjectType } from './useTopLevelObjectType';
import { DataSource, ObjectType } from './types';
import FlexDataLinkSkeleton from './components/FlexDataLinkSkeleton';

const FlexDataLinkPage: React.FC = () => {
  const { isTopLevelObjectTypesLoading, topLevelObjectTypes = [], isTopLevelObjectTypesError, status } = useTopLevelObjectType();

  const [selectedDataSource, setSelectedDataSource] = useState<DataSource | null>(null);
  const [objectTypes, setObjectTypes] = useState<ObjectType[] | null>(null);

  useEffect(() => {
    if (topLevelObjectTypes.length > 0) {
      setSelectedDataSource(topLevelObjectTypes[0]);
      setObjectTypes(topLevelObjectTypes[0].objectTypes);
    }
  }, [topLevelObjectTypes]);

  const dataSources = useMemo(
    () =>
      topLevelObjectTypes.map(({ dataSourceType, tenantId, dataSourceName }) => ({
        dataSourceType,
        tenantId,
        dataSourceName,
      })),
    [topLevelObjectTypes]
  );

  const handleDataSourceChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value; // e.g., "dataSourceType-tenantId"
    const [dataSourceType, tenantId] = selectedValue.split('::');
    const newSelectedDataSource = topLevelObjectTypes.find((ds) => ds.dataSourceType === dataSourceType && ds.tenantId === tenantId);
    if (newSelectedDataSource) {
      setSelectedDataSource(newSelectedDataSource);
      setObjectTypes(newSelectedDataSource.objectTypes);
    }
  };
  if (status === 'success' && topLevelObjectTypes.length <= 0) {
    return (
      <Box padding={2} data-testid="error-container">
        <p>No data available.</p>
      </Box>
    );
  }
  if (isTopLevelObjectTypesLoading) {
    return <FlexDataLinkSkeleton />;
  }

  if (isTopLevelObjectTypesError) {
    return (
      <Box padding={2} data-testid="error-container">
        <p>Error loading data. Please try again later.</p>
      </Box>
    );
  }

  return (
    <Box padding={2} sx={{ width: '100%' }}>
      <DataSourceSelect dataSources={dataSources} onChange={handleDataSourceChange} selectedDataSource={selectedDataSource as DataSource} />
      {selectedDataSource && objectTypes && <ObjectTypeTabs parentDataSource={selectedDataSource} objectTypes={objectTypes} />}
    </Box>
  );
};

export default FlexDataLinkPage;

import { Fade, Menu } from '@mui/material';
import { TOOLBAR_SPACING } from '../../helpers/toolbarStyleSettings';

const classes = {
  menu: (direction?: 'row' | 'column') => ({
    '& .MuiPaper-root': {
      borderRadius: TOOLBAR_SPACING.borderRadius,
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    '& .MuiList-root': {
      ...(direction === 'row' && {
        display: 'flex',
        flexDirection: 'row',
        padding: '4px',
      }),
    },
    ...(direction === 'row' && {
      '& .MuiMenuItem-root': {
        padding: '0px',
        marginRight: '1px',
      },
    }),
  }),
};

interface SpMuiMenuProps {
  anchorEl: HTMLElement | null;
  id: string;
  menuListProps: string;
  isMenuOpen: boolean;
  isActive?: boolean;
  handleClose: () => void;
  testId: string;
  children: React.ReactNode;
  direction?: 'row' | 'column';
}

const PyMuiMenu = ({ id, anchorEl, isMenuOpen, handleClose, menuListProps, testId, children, direction = 'column' }: SpMuiMenuProps) => {
  return (
    <Menu
      sx={classes.menu(direction)}
      data-testid={testId}
      id={id}
      MenuListProps={{
        'aria-labelledby': `${menuListProps}`,
      }}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      {children}
    </Menu>
  );
};

export default PyMuiMenu;

import { useTheme } from '@mui/material';
import { useEditor, EditorOptions, AnyExtension, EditorEvents } from '@tiptap/react';
import { useEffect } from 'react';
import { setTipTapEditorConfig, setTipTapExtensions } from '../helpers/config';

import { ImageUploadOptions, ITextEditorOption } from '../types';

export type TextEditorProps = {
  placeholder?: string;
  blockId?: string;
  onChange?: (blockId: string, value: string) => void;
  value?: string;
  tab: 'editor' | 'preview';
  user?: ITextEditorOption;
  mentions?: ITextEditorOption[];
  userPathname?: string;
  uploadFileOptions?: Omit<ImageUploadOptions, 'type'>;
} & Partial<EditorOptions>;

export const useTextEditor = ({ blockId, onChange, value, tab, editable = true, ...editorOptions }: TextEditorProps) => {
  const theme = useTheme();

  const editor = useEditor({
    content: value,
    extensions: [...setTipTapExtensions] as AnyExtension[],
    onUpdate: ({ editor }: EditorEvents['update']) => {
      if (blockId) {
        const html = editor.getHTML();
        onChange?.(blockId, html);
      }
    },
    ...editorOptions,
  });

  useEffect(() => {
    if (!editor) return;
    if (value && editor.isEmpty) {
      editor.commands.setContent(value);
    }
  }, [editor, value]);

  useEffect(() => {
    setTipTapEditorConfig(editor, editable, tab);
  }, [editor, tab, editable, theme]);

  return editor;
};

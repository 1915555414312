import { IntegrationMarketplaceCard, IntegrationType } from 'pages/integrations/interface';

export function getIntegrationRedirect(integration: IntegrationMarketplaceCard): string {
  if (integration.typeKey === IntegrationType.CHAT) {
    return `${globalThis.env.VITE_LEGACY_APP_URL}/settings/chat`;
  }

  if (integration.external) {
    return `${globalThis.env.VITE_LEGACY_APP_URL}/integration/${integration.identifier}`;
  }

  return `/integrations/${integration.identifier}`;
}

import { MenuItem, MenuItemProps, styled, SxProps } from '@mui/material';

import { TOOLBAR_COLORS, TOOLBAR_FONT } from '../../helpers/toolbarStyleSettings';
import { MouseEventHandler, ReactNode } from 'react';

interface CustomMenuItemProps {
  isActive?: boolean;
  isFontWeightBold?: boolean;
  fontSize?: number;
}

const CustomMenuItem = styled(MenuItem)<CustomMenuItemProps>(({ theme, isActive, isFontWeightBold, fontSize }) => ({
  padding: '6px 16px',
  backgroundColor: isActive ? theme.palette.grey[100] : 'transparent',
  fontWeight: isFontWeightBold ? 700 : 400,
  fontSize: fontSize || TOOLBAR_FONT.fontSize,
  fontFamily: 'inherit',
  lineHeight: '150%',
  '&:hover': {
    backgroundColor: TOOLBAR_COLORS.hoverColor,
    color: TOOLBAR_COLORS.primary,
  },
  '&:focus': {
    backgroundColor: TOOLBAR_COLORS.focusColor,
  },
}));

interface SpMuiMenuItemProps extends CustomMenuItemProps, MenuItemProps {
  menuItem: string | ReactNode;
  onClick?: MouseEventHandler<HTMLLIElement> | undefined;
  testId: string;
  isFontFamily?: boolean;
  customStyle?: SxProps;
}

const PyMuiMenuItem = ({
  onClick,
  testId,
  menuItem,
  isActive,
  fontSize,
  isFontWeightBold,
  isFontFamily,
  customStyle,
}: SpMuiMenuItemProps) => {
  return (
    <CustomMenuItem
      data-testid={testId}
      isActive={isActive}
      fontSize={fontSize}
      isFontWeightBold={isFontWeightBold}
      onClick={onClick}
      sx={{ fontFamily: isFontFamily ? `${menuItem}` : 'inherit', ...customStyle }}
    >
      {menuItem}
    </CustomMenuItem>
  );
};

export default PyMuiMenuItem;
